import React from 'react';
import styled from 'styled-components';

const DEFAULT_TYPEFORM_LINK = 'https://localphysionetwork.typeform.com/to/JGohptmy';

const TypeformCard: React.FC<{ suburbName: string }> = ({ suburbName }) => {
	const name = suburbName || 'Australian';

	return (
		<Container>
			<TypeFormBlock>
				<HeadingPart>
					<H2>{`${name} Physiotherapy clinics`}</H2>
				</HeadingPart>
				<a href={DEFAULT_TYPEFORM_LINK} target='_blank' rel='noopener noreferrer'>
					<Span>list your appointments for free</Span>
				</a>
			</TypeFormBlock>
		</Container>
	);
};

const Container = styled.div`
	flex: 1 1 auto;
	width: 100%;
	padding: 0 0 70px;
`;

const TypeFormBlock = styled.div`
	display: flex;
	align-items: center;
	font-size: 18px;
	font-weight: 700;
	color: #29705f;
	background: ${({ theme }) => theme.palette.white};
	border: 2px solid #29705f;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 18px 24px;
	flex-wrap: wrap;

	@media (max-width: 1225px) {
		padding: 13px 16px 13px 16px;
		& a {
			flex: 0 0 100%;
		}
	}
	@media (min-width: 1225px) {
		transition: all 0.3s ease;
		&:hover {
			box-shadow: 0px 0px 10px 2px rgb(41 112 95 / 18%);
		}
	}
`;

const HeadingPart = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
`;
const H2 = styled.h2`
	font-weight: 700 !important;
	margin-right: 5px;
`;

const Span = styled.span`
	font-weight: 500;
	color: #29705f;
	text-decoration: underline;
	transition: all 0.3s ease;

	&:hover {
		text-decoration: none;
	}
	@media (max-width: 1225px) {
		display: block;
		padding: 8px 0 0;
	}
`;

export default TypeformCard;
