import React from 'react';
import styled, { css } from 'styled-components';

import bird from 'images/icons/confirmedIcon.svg';
import warning from 'images/error/warning.png';
import manager from 'images/error/manager.jpeg';

interface IMessage {
	isSuccess?: boolean;
	confirmedName: string;
}

const MessageContainer: React.FC<IMessage> = ({ isSuccess, confirmedName }) => {
	return (
		<Main>
			<TopWrap isSuccess={isSuccess!}>
				<IconBox isSuccess={isSuccess!}>
					<img src={isSuccess ? bird : warning} alt='icon' />
				</IconBox>
				<Text isSuccess={isSuccess!}>
					{isSuccess ? (
						`Hey ${confirmedName}, your booking has been confirmed!`
					) : (
						<>
							<Span>Hey {confirmedName}</Span>
							{`, unfortunately we're experiencing some technical difficulties.`}
						</>
					)}
				</Text>
				{!isSuccess && <Underlined>Your booking could not be made at this time</Underlined>}
			</TopWrap>
			{!isSuccess && (
				<BottomWrap>
					<ManagerImg>
						<img src={manager} alt='icon' />
					</ManagerImg>
					<Info>
						<SpanBottom>Susan Woo</SpanBottom>
						{`, Customer Support Lead`}
					</Info>
					<InfoBox>
						One of our friendly support team will reach out ASAP to help you arrange an appointment that
						best suits you.
					</InfoBox>
				</BottomWrap>
			)}
		</Main>
	);
};

const Main = styled.div``;

const TopWrap = styled.div<{ isSuccess: boolean }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: ${({ isSuccess }) => (isSuccess ? '60px 16px' : '30px 16px')};
	background-color: ${({ isSuccess }) => !isSuccess && '#f7f8f9'};

	@media (max-width: 767px) {
		padding: ${({ isSuccess }) => (isSuccess ? '40px 16px' : '30px 16px')};
	}
	@media (max-width: 340px) {
		padding: 16px 16px;
	}
`;

const BottomWrap = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 15px 16px;

	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #000000;

	@media (max-width: 340px) {
		padding: 16px 16px;
	}
`;

const IconBox = styled.div<{ isSuccess: boolean }>`
	width: ${({ isSuccess }) => (isSuccess ? '50px' : '60px')};
	height: ${({ isSuccess }) => (isSuccess ? '50px' : '60px')};
	margin-bottom: ${({ isSuccess }) => (isSuccess ? '30px' : '20px')};
	position: relative;
	cursor: pointer;
	& > img,
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;

		transition: all 0.5s ease;

		@media (min-width: 767px) {
			&:hover {
				transform: scale(1.2);
			}
		}
	}

	${({ isSuccess }) =>
		!isSuccess &&
		css`
			@media (max-width: 500px) {
				width: 40px;
				height: 40px;
			}
		`}

	@media (max-width: 340px) {
		width: 35px;
		height: 35px;
		margin-bottom: 16px;
	}
`;
const Text = styled.p<{ isSuccess: boolean }>`
	font-weight: normal;
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	color: ${({ theme, isSuccess }) => (isSuccess ? theme.palette.green : '#4b4b4b')};
`;
const Span = styled.span`
	font-weight: 600;
`;
const Underlined = styled.p`
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #4b4b4b;
	text-decoration: underline;
	margin-top: 20px;
`;

const ManagerImg = styled.div`
	width: 100px;
	height: 100px;
	margin: 10px 10px 5px;
	position: relative;
	& > img,
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	@media (max-width: 500px) {
		width: 80px;
		height: 80px;
	}
`;

const Info = styled.p`
	margin: 0 0 20px;
`;

const SpanBottom = styled(Span)`
	font-weight: 500;
`;
const InfoBox = styled.div`
	max-width: 600px;
	padding: 20px 25px;
	border-radius: 6px;
	background: #edf5f3;
	margin-bottom: 20px;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: calc(50% - 16px);
		width: 0;
		height: 0;
		border-left: 16px solid transparent;
		border-right: 16px solid transparent;
		border-bottom: 16px solid #edf5f3;
		transform: translateY(-98%);
	}
`;

export default MessageContainer;
