import React from 'react';
import styled, { css } from 'styled-components';

import { ClinicCard } from 'templates/booking';
import { IOption } from './HeaderTop';
import { IAppointmentDateData } from './Main';
import { IAvailabilityPageInformation, SuburbData } from 'apiFolder/directus-main';

import sortUpDownIcon from 'images/icons/sortUpDownIcon.svg';

import Clinics from 'components/booking/Clinics';
import ClinicsError from 'components/booking/ClinicsZeroResults';
import PageInfoBlock from 'components/booking/PageInfoBlock';
import QuickLinksBlock from 'components/booking/QuickLinksBlock';
import FooterBooking from 'components/booking/FooterBooking';
import TypeformCard from 'components/booking/TypeformCard';
import SkeletonComponent from 'components/booking/skeleton/SkeletonComponent';

export interface IClinics {
	isLoaded: boolean;
	clinics: ClinicCard[];
	activeClinic: ClinicCard | null;
	suburb: IOption;
	item?: SuburbData;
	data: IAppointmentDateData | null;
	pageInfo?: IAvailabilityPageInformation;
	isZeroResults?: boolean;
	isSuburbSelected?: boolean;
	setActiveClinic: (clinic: ClinicCard) => void;
	setHoveredClinicCard: (clinic: ClinicCard | null) => void;
	onTimeCellClick: (infoData: IAppointmentDateData) => void;
}

const SKELETON_CARDS = ['1', '2', '3'];

const ClinicsMainContainer: React.FC<IClinics> = ({
	isLoaded,
	clinics,
	activeClinic,
	suburb,
	item,
	onTimeCellClick,
	data,
	pageInfo,
	isSuburbSelected,
	setActiveClinic,
	setHoveredClinicCard,
}) => {
	return (
		<Wrapper>
			<Inner>
				<TitleBlock>
					<TitleWrap>{item ? <Title>{item.h1}</Title> : <Title>Book a Physio in Australia</Title>}</TitleWrap>
					{!isSuburbSelected ||
						!isLoaded ||
						(!!clinics?.length && (
							<SortBlock isDisabled>
								<IconBox>
									<img src={sortUpDownIcon} alt='sort' width='7px' />
								</IconBox>
								Sort by:
								<SelectClosest>
									<Variant>closest</Variant>
								</SelectClosest>
							</SortBlock>
						))}
				</TitleBlock>
				{isLoaded ? (
					<>
						<Clinics
							isShow={!!isSuburbSelected}
							clinics={clinics}
							onTimeCellClick={onTimeCellClick}
							data={data}
							suburb={suburb}
							activeClinic={activeClinic!}
							setActiveClinic={setActiveClinic}
							setHoveredClinicCard={setHoveredClinicCard}
						/>
						{(!isSuburbSelected || !clinics || clinics.length < 1) && <ClinicsError />}
						<TypeformWrap isLoaded={isLoaded}>
							<TypeformCard
								suburbName={suburb?.name || suburb?.value?.structured_formatting.main_text || ''}
							/>
						</TypeformWrap>
					</>
				) : (
					<SkeletonComponent numberOfCards={SKELETON_CARDS} />
				)}
			</Inner>
			<Container isLoaded={isLoaded}>
				{(item || pageInfo) && <PageInfoBlock item={item} pageInfo={pageInfo} />}
				<QuickLinksBlock />
				<FooterBooking />
			</Container>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	min-height: calc(100vh - 150px);
	flex: 0 0 770px;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;

	@media (max-width: 1225px) {
		flex: 0 0 460px;
		width: 460px;
	}
	@media (max-width: 1023px) {
		flex: 0 0 414px;
		width: 414px;
	}

	@media (max-width: 670px) {
		flex: 0 0 100%;
		width: auto;
		margin: 0 auto;
	}
`;

const Inner = styled.div`
	flex: 1 0 auto;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0 26px 24px;
	scroll-behavior: smooth;
	@media (max-width: 1023px) {
		padding: 0 16px 24px;
	}
`;
const TitleBlock = styled.div`
	width: calc(100% + 30px);
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ theme }) => theme.palette.bg};
	padding: 40px 15px 40px 30px;
	margin: 0px -15px;
	z-index: 10;

	@media (max-width: 1280px) {
		padding: 25px 16px;
	}

	@media (max-width: 767px) {
		top: 185px;
	}
`;

const TitleWrap = styled.div``;

const Title = styled.h1`
	font-family: MuseoSans !important;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.03em;
	color: ${({ theme }) => theme.palette.main};
`;

const SortBlock = styled.div<{ isDisabled: boolean }>`
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 14.1094px;
	line-height: 17px;
	white-space: nowrap;
	cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

	@media (max-width: 1225px) {
		display: none;
	}
`;

const IconBox = styled.div`
	display: flex;
	margin: 0 8px 0 16px;
	position: relative;
	height: 17px;
	width: 17px;

	& > img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

const SelectClosest = styled.span`
	width: 115px;
	display: flex;
	color: ${({ theme }) => theme.palette.purp};
	text-transform: capitalize;
`;
const Container = styled.div<{ isLoaded: boolean }>`
	${({ isLoaded }) =>
		!isLoaded &&
		css`
			position: absolute;
			display: none;
			width: 0;
			height: 0;
		`}
	${({ isLoaded }) =>
		isLoaded &&
		css`
			position: static;
			display: block;
			width: 100%;
			height: auto;
		`}
`;
const Variant = styled.div`
	border-bottom: 1px dashed #424bec;
	margin-left: 5px;
`;
const TypeformWrap = styled.div<{ isLoaded: boolean }>`
	${({ isLoaded }) =>
		!isLoaded &&
		css`
			position: absolute;
			display: none;
			width: 0;
			height: 0;
		`}
	${({ isLoaded }) =>
		isLoaded &&
		css`
			position: static;
			display: block;
			width: 100%;
			height: auto;
		`}
`;

export default ClinicsMainContainer;
