import React, { ReactNode } from 'react';

interface ITag {
	level: 1 | 2 | 3 | 5 | 6;
	children?: ReactNode;
}

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const HeadingComponent: React.FC<ITag> = ({ level, children = null }) => {
	const Tag = `h${level}` as HeadingTag;
	return <Tag>{children}</Tag>;
};

export default HeadingComponent;
