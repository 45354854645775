import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from 'images/Logo_blue.svg';
import GooglePlacesAutocomplete from 'components/booking/GooglePlacesAutocomplete';
import { IGoogleValue } from 'components/HomePage/Searching/SearchingCopy';
import TypeFormComponent from 'components/common/TypeForm';

export interface IOption {
	id: number;
	name: string;
	state: string;
	value?: IGoogleValue;
	slug?: string;
	urlSlug?: string;
	label: string;
	location: { lat: number; lng: number } | null;
	suburbName?: string;
	description?: string;
}

interface IBookingHeaderTop {
	isConfirm?: boolean;
	isBurgerOpen: boolean;
	suburb: IOption;
	slug: string | null;
	setSuburb: (key: IOption) => void;
	toggleBurgerMenu: () => void;
	setIsLoading: (key: boolean) => void;
	setIsMapLoading: (key: boolean) => void;
}

const BookingHeaderTop: React.FC<IBookingHeaderTop> = ({
	setSuburb,
	suburb,
	isBurgerOpen,
	toggleBurgerMenu,
	setIsLoading,
	setIsMapLoading,
	slug,
}) => {
	return (
		<Wrapper>
			<InnerTop>
				<BurgerWrapper className={isBurgerOpen ? 'active' : ''} onClick={toggleBurgerMenu}>
					<Line />
					<Line />
					<Line />
				</BurgerWrapper>
				<LogoWrapper>
					<Link to='/'>
						<img src={Logo} alt='' width='187px' />
					</Link>
				</LogoWrapper>
				<>
					<InputWrap>
						<GooglePlacesAutocomplete
							suburb={suburb}
							setSuburb={setSuburb}
							setIsLoading={setIsLoading}
							setIsMapLoading={setIsMapLoading}
							slug={slug}
						/>
					</InputWrap>
					<NavigationWrapper>
						<Navigation>
							<Link to='/near-me'>
								<Item>Near Me</Item>
							</Link>
							<Link to={'/patient-resources'}>
								<Item>Patient Resources</Item>
							</Link>
							<Link to='/about'>
								<Item>About Us</Item>
							</Link>
						</Navigation>
						<TypeFormComponent>
							<Button>List your clinic</Button>
						</TypeFormComponent>
					</NavigationWrapper>
				</>
			</InnerTop>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	border-bottom: 1px solid ${({ theme }) => theme.palette.inactive};
	background-color: ${({ theme }) => theme.palette.white};
	z-index: 105;

	& a {
		margin-left: 20px;
	}
`;

const InnerTop = styled.div`
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14.5px 16px;
	margin: 0 auto;

	@media (max-width: 1280px) {
		& > img {
			max-width: 150px;
			width: 100%;
		}
	}

	@media (max-width: 767px) {
		flex-wrap: wrap;
		padding: 8px 16px;
		& > :nth-child(3) {
			order: 4;
			width: calc(100% + 32px);
			padding: 8px 16px 0;
			margin-left: -16px;
			margin-top: 8px;
			border-top: 1px solid ${({ theme }) => theme.palette.inactive};
		}
		& > :nth-child(1) {
			order: 1;
		}
		& > :nth-child(2) {
			order: 2;
		}
		& > :nth-child(4) {
			order: 3;
		}
	}
`;

const BurgerWrapper = styled.div`
	width: 40.5px;
	height: 34.5px;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	margin-right: 3px;
	cursor: pointer;
	position: relative;
	left: -10px;
	padding: 10px;

	@media (max-width: 1023px) {
		display: flex;
		&.active {
			& > div:nth-of-type(1) {
				transform: rotate(45deg) translate(4px, 4px);
				width: 20px;
			}

			& > div:nth-of-type(2) {
				opacity: 0;
				pointer-events: none;
			}

			& > div:nth-of-type(3) {
				transform: rotate(-45deg) translate(5px, -5px);
				width: 20px;
			}
		}
	}
`;

const Line = styled.div`
	height: 1.5px;
	background-color: black;
	display: block;
	transition: all 0.3s ease;
`;

const LogoWrapper = styled.div`
	display: inline-block;
	@media (max-width: 767px) {
		flex: 1 1 auto;
		& img {
			width: 150px;
		}

		& a {
			margin-left: 5px;
		}
	}
	@media (max-width: 414px) {
		flex: 0 1 120px;
		& img {
			width: 120px;
		}
	}
	@media (max-width: 355px) {
		flex: 0 1 90px;
		margin-top: 5px;
		& img {
			width: 100px;
		}
	}
`;

const InputWrap = styled.div`
	flex: 1 0 auto;
	margin-left: 40px;
	@media (max-width: 1280px) {
		margin-left: 20px;
	}
	@media (max-width: 767px) {
		margin-left: 0;
		& > div {
			height: 48px;
		}
	}
`;

const NavigationWrapper = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding-right: 20px;
	@media (max-width: 1367px) {
		padding-right: 0;
	}
`;
const Navigation = styled.ul`
	display: flex;
	list-style: none;
	align-items: center;
	margin: 0;
	padding: 0;

	@media (max-width: 1023px) {
		display: none;
	}
`;

const Item = styled.li`
	margin: 0 20px;
	padding: 0;
	font-size: 16px;
	font-weight: 300;
	color: ${({ theme }) => theme.palette.main};
	cursor: pointer;

	transition: all 0.3s ease;

	&:hover {
		color: ${({ theme }) => theme.palette.purp};
	}

	@media (max-width: 1280px) {
		margin: 0 10px;
	}

	@media (max-width: 978px) {
		font-size: 14px;
	}
	@media (max-width: 918px) {
		margin: 0 15px;
	}
`;

const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	background: ${({ theme }) => theme.palette.purp};
	font-weight: 400;
	font-size: 14px;
	color: ${({ theme }) => theme.palette.white};
	padding: 5px;
	border: 1px solid ${({ theme }) => theme.palette.purp};
	border-radius: 4px;
	cursor: pointer;
	margin-left: 20px;
	height: 50px;
	width: 144px;
	transition: all 0.3s ease;

	&:hover {
		color: ${({ theme }) => theme.palette.main};
		background: ${({ theme }) => theme.palette.bg_purp};
		border: 1px solid ${({ theme }) => theme.palette.inactive};
	}

	@media (max-width: 767px) {
		height: 40px;
	}

	@media (max-width: 385px) {
		width: 124px;
	}

	@media (max-width: 340px) {
		margin-left: 10px;
	}
`;

export default BookingHeaderTop;
