import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import useToggle from 'hooks/useToggle';

export interface IVariant {
	icon?: string;
	title: string;
	[key: string]: any;
}

interface IProps {
	variants: IVariant[] | null;
	chosenOption: IVariant | null;
	placeholder?: string;
	onChoose: (variant: IVariant | null) => void;
	onOpen?: () => void;
	label?: string;
	isTransparent?: boolean;
	isSemiBold?: boolean;
	isUpperCase?: boolean;
	isBlack?: boolean;
	isForm?: boolean;
	height?: number;
	icon?: string;
	id?: string;
}

const DropDown: React.FC<IProps> = ({
	variants,
	chosenOption,
	placeholder,
	onChoose,
	onOpen,
	label = null,
	isTransparent = false,
	isSemiBold = false,
	isUpperCase = false,
	isBlack = false,
	isForm = false,
	height = 200,
	icon,
	id,
}) => {
	const [isOpen, setIsOpen] = useToggle(false);
	const [isOpening, setIsOpening] = useToggle(false);
	const [isClosing, setIsClosing] = useToggle(false);
	const [userVariant, setUserVariant] = useState<IVariant | null>(null);
	const [isChosen, setIsChosen] = useState<boolean>(false);

	const handleOptionClick = () => {
		if (isOpen) {
			setIsClosing(true);
			setIsOpening(false);
			setIsOpen(false);
		} else {
			setIsOpening(true);
			setIsClosing(false);
			setIsOpen(true);
			if (onOpen) {
				onOpen();
			}
		}
	};

	const handleVariantChoose = (variant: IVariant | null) => {
		onChoose(variant);
		setIsClosing(true);
		setIsOpening(false);
		setIsOpen(false);
		if (!variant?.title) {
			setIsChosen(false);
			return;
		}
		if (variant?.title !== placeholder) {
			setIsChosen(true);
		}
		setUserVariant(variant);
	};

	const handleInputBlur = () => {
		setTimeout(() => {
			setIsOpen(false);
			setIsClosing(true);
			setIsOpening(false);
		}, 100);
	};

	return (
		<SelectContainer isLabel={label !== null}>
			{label && (
				<LabelBlock>
					<Label isTransparent={isTransparent}>{label}</Label>
				</LabelBlock>
			)}
			<Select isOpen={isOpen} isTransparent={isTransparent}>
				<OptionsWrap onClick={handleOptionClick} tabIndex={0} onBlur={handleInputBlur}>
					<Option
						onClick={() => handleVariantChoose(chosenOption)}
						isSemiBold={isSemiBold}
						isForm={isForm}
						isText={true}
					>
						<ChosenOptionText
							isTransparent={isTransparent}
							isBlack={isBlack}
							userVariant={userVariant}
							isChosen={isChosen}
						>
							{chosenOption?.icon && <OptionIcon src={chosenOption.icon} />}
							{isUpperCase
								? chosenOption?.title.toUpperCase() || placeholder?.toUpperCase()
								: chosenOption?.title || placeholder}
						</ChosenOptionText>
						<IconDropDown src={icon} isOpening={isOpening} isClosing={isClosing} isOpen={isOpen} />
					</Option>
					<VariantsOptions height={height} isForm={isForm}>
						{isOpen &&
							variants!.map((option, index) => {
								if (chosenOption?.title !== option.title) {
									return (
										<Option
											key={option.title + index.toString()}
											isForm={isForm}
											onClick={() => handleVariantChoose(option)}
											isSemiBold={isSemiBold}
											isText={option.title !== ''}
										>
											{option.title && (
												<OptionText isTransparent={isTransparent}>
													{option.icon && <OptionIcon src={option.icon} />}
													{isUpperCase ? option.title.toUpperCase() : option.title}
												</OptionText>
											)}
										</Option>
									);
								}
								return null;
							})}
					</VariantsOptions>
				</OptionsWrap>
			</Select>
		</SelectContainer>
	);
};

const SelectContainer = styled.div<{ isLabel: boolean }>`
	// height: ${({ isLabel }) => (isLabel ? '70px' : '40px')};
	height: 44px;
	width: 100%;
	position: relative;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: ${({ theme }) => theme.palette.main};
	transition: color 0.45s ease-in;
`;

const LabelBlock = styled.div`
	// height: 30px;
	display: flex;
	align-items: center;
`;

const Label = styled.p<{ isTransparent: boolean }>`
	margin: ${({ isTransparent }) => (isTransparent ? '0 8px 0px 16px' : '0 8px 4px 0')};
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: ${({ theme }) => theme.palette.grey_text};
	transition: color 0.45s ease-in;
`;

const Select = styled.div<{ isOpen: boolean; isTransparent: boolean }>`
	position: relative;
	z-index: 15;
	cursor: pointer;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.palette.white};
	width: 100%;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	margin: 0;
	display: flex;
	border: ${({ isOpen, theme }) =>
		isOpen ? `1px solid ${theme.palette.purp}` : `1px solid ${theme.palette.items_border}`};
	transition: 0.45s ease-in;

	box-shadow: ${({ isOpen }) => isOpen && '0px 4px 4px rgba(0, 0, 0, 0.1)'};
	z-index: ${({ isOpen }) => isOpen && '10'};
	&::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
	-ms-overflow-style: none;
`;

const ChosenOptionText = styled.div<{
	isTransparent: boolean;
	isChosen: boolean;
	isBlack: boolean;
	userVariant: IVariant | null;
}>`
	display: ${({ userVariant }) => userVariant && 'none'};
	margin: 0;
	padding: 0;
	padding-right: 5px;
	display: flex;
	align-items: center;

	color: ${({ theme, isTransparent, isBlack }) =>
		isTransparent && (isBlack ? `${theme.palette.modal_bg_text}` : `${theme.palette.purp}`)};

	color: ${({ theme, isChosen }) => (isChosen ? `${theme.palette.main}` : `${theme.palette.modal_bg_text}`)};

	@media (max-width: 330px) {
		white-space: normal;
	}
`;

const OptionsWrap = styled.div`
	width: 100%;
`;

const VariantsOptions = styled.div<{ height: number; isForm: boolean }>`
	width: 100%;
	max-height: ${({ height }) => `${height}px`};
	overflow-y: auto;

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.palette.modal_bg_text};
		border-radius: ${({ isForm }) => (isForm ? '4px' : '3px')};
	}

	scrollbar-color: ${({ theme }) => theme.palette.main};
	scrollbar-width: none;

	&::-webkit-scrollbar {
		transition: all 0.4s ease-in;
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
		-webkit-box-shadow: inset 0 0 0 transparent;
		margin: 3px 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 5px;
		outline: 2px solid transparent;
		height: 50%;
	}

	&:hover::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.45);
	}

	&::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.55);
	}
`;

interface IIcon {
	isOpening: boolean;
	isClosing: boolean;
	isOpen: boolean;
}

const IconDropDown = styled.img<IIcon>`
	transition: all 0.5s ease 0s;
	transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

	${({ isOpening }) =>
		isOpening &&
		css`
			animation: ${rotateIconOpenAnimation} 0.5s;
		`}

	${({ isClosing }) =>
		isClosing &&
		css`
			animation: ${rotateIconCloseAnimation} 0.5s;
		`}
`;

const rotateIconOpenAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

const rotateIconCloseAnimation = keyframes`
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

const Option = styled.div<{ isSemiBold: boolean; isForm: boolean; isText: boolean }>`
	width: 100%;
	min-height: ${({ isForm }) => (isForm ? '46px' : '38px')};
	padding: ${({ isForm }) => (isForm ? '9px 9px' : '7px 15px')};
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	// font-weight: ${({ isSemiBold }) => isSemiBold && 600};

	display: ${({ isText }) => !isText && 'none'};
`;

const OptionText = styled.div<{ isTransparent: boolean }>`
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 400;
	color: ${({ theme, isTransparent }) => isTransparent && `${theme.palette.main}`};
	transition: all 0.3s ease;

	&:hover {
		color: ${({ theme }) => `${theme.palette.purp}`};
	}
`;

const OptionIcon = styled.img`
	margin-right: 8px;
`;

export default DropDown;
