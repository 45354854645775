/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { OptionTypeBase, OptionsType } from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import GooglePlacesAutocompleteProps, {
	AutocompletionRequest,
	GooglePlacesAutocompleteHandle,
} from './Autocomplete.types';
import autocompletionRequestBuilder from 'helpers/autocompletionRequestBuilder';
import { Loader } from '@googlemaps/js-api-loader';

import geocodeByPlaceId from 'utils/geocodeByPlaceId';
import { AUSTRALIA_CENTER } from 'components/booking/map/GoogleMapFC';

interface IOptionType extends OptionTypeBase {
	value?: number;
	label?: string;
}

const GooglePlacesAutocomplete: React.ForwardRefRenderFunction<
	GooglePlacesAutocompleteHandle,
	GooglePlacesAutocompleteProps
> = (
	{
		apiKey = '',
		apiOptions = {},
		autocompletionRequest = {},
		debounce = 500,
		minLengthAutocomplete = 0,
		selectProps = {},
		onLoadFailed = console.error,
		withSessionToken = false,
	}: GooglePlacesAutocompleteProps,
	ref,
): React.ReactElement => {
	const [placesService, setPlacesService] = useState<google.maps.places.AutocompleteService | undefined>(undefined);
	const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | undefined>(
		undefined,
	);

	const [fetchSuggestions] = useDebouncedCallback(
		(value: string, cb: (options: OptionsType<IOptionType>) => void): void => {
			if (!placesService) return cb([]);
			if (value.length < minLengthAutocomplete) return cb([]);

			const autocompletionReq: AutocompletionRequest = { ...autocompletionRequest };

			placesService.getPlacePredictions(
				autocompletionRequestBuilder(autocompletionReq, value, withSessionToken && sessionToken),
				(suggestions) => {
					const suggestionsGeocodePromises = (suggestions || []).map((suggestion) => {
						const placeId = suggestion.place_id;

						return geocodeByPlaceId(placeId).then((results) => {
							const [place] = results;
							return {
								...place,
								suggestion,
							};
						});
					});

					Promise.all(suggestionsGeocodePromises)
						.then((results) => {
							const options = (results || []).map((place) => {
								const option = {
									label: '',
									value: {} as google.maps.places.AutocompletePrediction,
									location: {
										lat: AUSTRALIA_CENTER.lat,
										lng: AUSTRALIA_CENTER.lng,
									},
									urlSlug: '',
								};
								const { long_name: postalCode = '' } =
									place.address_components.find((c) => c.types.includes('postal_code')) || {};
								const { short_name: subName = '' } =
									place.address_components.find((c) => c.types.includes('locality')) || {};
								const { short_name: subAdminName = '' } =
									place.address_components.find((c) =>
										c.types.includes('administrative_area_level_1'),
									) || {};

								const label = `${subName} ${subAdminName}${postalCode ? `, ${postalCode}` : ''}`;
								option.label = label.length > 1 ? label : place.formatted_address;
								option.value = place.suggestion;
								option.location.lat = place.geometry.location.lat();
								option.location.lng = place.geometry.location.lng();

								option.urlSlug = `/${subAdminName}/${subName}${postalCode ? `-${postalCode}` : ''}`
									.toLowerCase()
									.replaceAll(' ', '-');

								return option;
							});
							cb(options);
						})
						.catch((err) => console.log(err));
				},
			);
		},
		debounce,
	);

	const initializeService = () => {
		if (!window.google) throw new Error('[react-google-places-autocomplete]: Google script not loaded');
		if (!window.google.maps) throw new Error('[react-google-places-autocomplete]: Google maps script not loaded');
		if (!window.google.maps.places)
			throw new Error('[react-google-places-autocomplete]: Google maps places script not loaded');

		setPlacesService(new window.google.maps.places.AutocompleteService());
		setSessionToken(new google.maps.places.AutocompleteSessionToken());
	};

	useImperativeHandle(
		ref,
		() => ({
			getSessionToken: () => {
				return sessionToken;
			},
			refreshSessionToken: () => {
				setSessionToken(new google.maps.places.AutocompleteSessionToken());
			},
		}),
		[sessionToken],
	);

	useEffect(() => {
		const init = async () => {
			try {
				if (!window.google || !window.google.maps || !window.google.maps.places) {
					await new Loader({ apiKey, ...{ libraries: ['places'], ...apiOptions } }).load();
				}
				initializeService();
			} catch (error) {
				onLoadFailed(error);
			}
		};

		if (apiKey) init();
		else initializeService();
	}, []);

	return (
		<AsyncSelect {...selectProps} loadOptions={fetchSuggestions} getOptionValue={({ value }) => value.place_id} />
	);
};

export default forwardRef(GooglePlacesAutocomplete);
