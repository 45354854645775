import React from 'react';
import styled, { css } from 'styled-components';

import errorMap from 'images/mapError.jpg';

const Map: React.FC<{ isZeroResults: boolean; children: React.ReactNode }> = ({ isZeroResults, children }) => {
	if (isZeroResults) {
		return (
			<MapWrap isZeroResults={isZeroResults}>
				<img src={errorMap} alt='Australia' />
			</MapWrap>
		);
	}

	return <MapWrap isZeroResults={isZeroResults}>{children}</MapWrap>;
};

const MapWrap = styled.div<{ isZeroResults: boolean }>`
	position: sticky;
	top: 150px;
	width: 100%;
	height: calc(100vh - 150px);
	background-color: ${({ theme }) => theme.palette.bg_purp};

	${({ isZeroResults }) =>
		isZeroResults &&
		css`
			& > img {
				positon: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		`}

	@media (max-width: 767px) {
		top: 185px;
		height: calc(100vh - 185px);
	}
	@media (max-width: 670px) {
		display: none;
	}
`;

export default Map;
