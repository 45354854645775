import { useEffect, useState } from 'react';

const useCountdown = (time: number, isRunning: boolean, setIsRunnig: (key: boolean) => void) => {
	const [countDown, setCountDown] = useState(time);

	useEffect(() => {
		if (!isRunning) return;

		if (countDown === 0) {
			setIsRunnig(false);
			setCountDown(time);
			return;
		}

		const interval = setInterval(() => {
			setCountDown(countDown - 1);
		}, 1000);

		return () => clearInterval(interval);
	}, [countDown, isRunning]);

	return countDown;
};

export default useCountdown;
