import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import TabContainer from 'components/booking/TabContainer';
import TypeFormComponent from 'components/common/TypeForm';

const QuickLinksBlock: React.FC = () => {
	return (
		<TabContainer text='Quick links'>
			<Row>
				<Column>
					<Title>COMPANY</Title>
					<Link to='/about'>
						<Text>About</Text>
					</Link>
					<Link to='/about/team'>
						<Text>Team</Text>
					</Link>
					{/* <Link to='/'> */}
					<Text>FAQ</Text>
					{/* </Link> */}
					<Link to='/contact-us'>
						<Text>Contact us</Text>
					</Link>
					<Link to='/careers'>
						<Text>Careers</Text>
					</Link>
					<Link to='/media'>
						<Text>Media</Text>
					</Link>
					{/* <Link to='/'> */}
					<Text>Investors</Text>
					{/* </Link> */}
					{/* <Link to='/'> */}
					<Text>Blog</Text>
					{/* </Link> */}
					<Link to='/terms-and-conditions'>
						<Text>Terms</Text>
					</Link>
					<Link to='/privacy-policy'>
						<Text>Privacy Policy</Text>
					</Link>
				</Column>
				<Column>
					<Title>FOR PATIENTS</Title>
					<Link to='/patient-resources'>
						<Text>Patient resources</Text>
					</Link>
					<Link to='/physiotherapy'>
						<Text>Physiotherapy services</Text>
					</Link>
					{/* <Link to='/'> */}
					<Text>FAQs</Text>
					{/* </Link> */}
					<Link to='/pain'>
						<Text>Pain articles</Text>
					</Link>
					<Link to='/injuries'>
						<Text>Injury articles</Text>
					</Link>
					<Link to='/conditions'>
						<Text>Conditions</Text>
					</Link>
					{/* <Link to='/'> */}
					{/* <Text>Back pain overview</Text> */}
					{/* </Link> */}
					{/* <Link to='/'> */}
					{/* <Text>Tennis elbow overview</Text> */}
					{/* </Link> */}
					<Link to='/popular-clinics'>
						<Text>Popular clinics</Text>
					</Link>
					<Link to='/popular-locations'>
						<Text>Popular locations</Text>
					</Link>
				</Column>
				<Column>
					<Title>POPULAR SEARCHES</Title>
					<Link to='/sydney'>
						<Text>Physio in Sydney</Text>
					</Link>
					<Link to='/brisbane'>
						<Text>Physio in Brisbane</Text>
					</Link>
					<Link to='/melbourne'>
						<Text>Physio in Melbourne</Text>
					</Link>
					<Link to='/adelaide'>
						<Text>Physio in Adelaide</Text>
					</Link>
					<Link to='/near-me'>
						<Text>Physio near me</Text>
					</Link>
					<Link to='/find'>
						<Text>Find a physio</Text>
					</Link>
					<Link to='/sports-physio'>
						<Text>Sports physiotherapy</Text>
					</Link>
					<Link to='/nsw/sydney-cbd-2000'>
						<Text>Sydney CBD physio</Text>
					</Link>
					<Link to='/qld/brisbane-cbd-4000'>
						<Text>Brisbane CBD physio</Text>
					</Link>
					<Link to='/vic/melbourne-cbd-3000'>
						<Text>Melbourne CBD physio</Text>
					</Link>
				</Column>
				<Column>
					<Title>FOR CLINICS</Title>
					<TypeFormComponent>
						<TextHovered>Clinic registration</TextHovered>
					</TypeFormComponent>
					{/* <Link to='/'> */}
					<Text>Blog</Text>
					{/* </Link> */}
				</Column>
			</Row>
		</TabContainer>
	);
};

const Row = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;

	@media (max-width: 1225px) {
		justify-content: space-between;
	}
`;

const Column = styled.div`
	flex: 0 1 300px;
	width: 300px;
	padding-right: 20px;
	margin: 0 0 40px;

	& a {
		color: ${({ theme }) => theme.palette.main};
		transition: all 0.3s ease;
		&:hover {
			color: ${({ theme }) => theme.palette.purp};
		}
	}

	@media (max-width: 1225px) {
		flex: 0 0 50%;
		width: 50%;

		&:nth-child(even) {
			flex: 0 0 41%;
			width: 41%;
			padding-right: 0px;
		}
	}
`;

const Title = styled.h5`
	font-family: 'MuseoSans', sans-serif;
	font-weight: 600 !important;
	font-size: 16px !important;
	line-height: 19px;
	color: ${({ theme }) => theme.palette.main};
	text-transform: uppercase;
	margin: 15px 0 7px;
`;

const Text = styled.div`
	font-family: 'MuseoSans', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	margin: 6px 0;
`;

const TextHovered = styled(Text)`
	color: ${({ theme }) => theme.palette.main};
	transition: all 0.3s ease;
	cursor: pointer;
	&:hover {
		color: ${({ theme }) => theme.palette.purp};
	}
`;

export default QuickLinksBlock;
