import React from 'react';
import styled, { css } from 'styled-components';
import { IAppointmentDateData } from './Main';

export interface ITimeCell {
	clinicId: number;
	time: string;
	appointmentId?: string;
	data: IAppointmentDateData | null;
	isAvaliable: boolean;
	onTimeCellClick: (data: IAppointmentDateData) => void;
}

const TimeCellContainer: React.FC<ITimeCell> = ({
	clinicId,
	data,
	time,
	isAvaliable,
	onTimeCellClick,
	appointmentId,
}) => {
	const handleClick = () => {
		const curData = data || {};
		if (isAvaliable) onTimeCellClick({ ...curData, selectedTime: time, appointmentId: appointmentId! });
	};
	return (
		<Container
			isAvail={isAvaliable}
			isChosen={time === data?.selectedTime && clinicId === data?.selectedClinicId}
			onClick={handleClick}
		>
			{time}
		</Container>
	);
};

const Container = styled.div<{ isAvail: boolean; isChosen: boolean }>`
	flex: 0 0 83px;
	width: 83px;
	height: 33px;
	display: flex;
	align-items: center;
	font-family: MuseoSans;
	font-weight: normal;
	font-size: 14px;
	${({ theme, isAvail }) =>
		isAvail &&
		css`
			color: ${theme.palette.purp};
			background: ${theme.palette.white};
			border: 1px solid ${theme.palette.purp};
			cursor: pointer;

			&:hover {
				color: ${theme.palette.white};
				background: ${theme.palette.purp};
				border: 1px solid ${theme.palette.purp};
			}
		`}

	${({ isAvail }) =>
		!isAvail &&
		css`
			color: #b5b5b4;
			background: #f4f3f4;
			border: 1px solid #f4f3f4;
		`}

	${({ theme, isAvail, isChosen }) =>
		isAvail &&
		isChosen &&
		css`
			color: ${theme.palette.fail};
			background: ${theme.palette.bg_purp};
			border: 1px solid ${theme.palette.fail};
		`}

	box-sizing: border-box;
	border-radius: 20px;
	padding: 8px 10px 8px 14px;
	margin: 8px 2px;
	white-space: nowrap;
	transition: all 0.3s ease;
`;

export default TimeCellContainer;
