export const THIS_YEAR = +new Date().getFullYear();

export const THIS_MONTH = +new Date().getMonth() + 1;

interface IWeekDays {
	[key: number]: string;
}

export const WEEK_DAYS: IWeekDays = {
	1: 'Su',
	2: 'Mo',
	3: 'Tu',
	4: 'We',
	5: 'Th',
	6: 'Fr',
	7: 'Sa',
};

const LONG_WEEK_DAYS: { [key: string]: number } = {
	Sunday: 1,
	Monday: 2,
	Tuesday: 3,
	Wednesday: 4,
	Thursday: 5,
	Friday: 6,
	Saturday: 7,
};

interface IMonths {
	[key: number]: string;
}

export const CALENDAR_MONTHS: IMonths = {
	1: 'January',
	2: 'February',
	3: 'March',
	4: 'April',
	5: 'May',
	6: 'June',
	7: 'July',
	8: 'August',
	9: 'September',
	10: 'October',
	11: 'November',
	12: 'December',
};

export const CALENDAR_WEEKS = 6;

export const zeroPad = (value: number, length: number) => `${value}`.padStart(length, '0');

export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
	const months30 = [4, 6, 9, 11];
	const leapYear = year % 4 === 0;

	return month === 2 ? (leapYear ? 29 : 28) : months30.includes(month) ? 30 : 31;
};

export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
	const dayName = new Intl.DateTimeFormat('en-US', {
		weekday: 'long',
	}).format(new Date(`${year}-${zeroPad(month, 2)}-01T01:00:00`));
	return LONG_WEEK_DAYS[dayName];
};

export const isDate = (date: Date) => {
	const isDateObj = Object.prototype.toString.call(date) === '[object Date]';
	const isValidDate = date && !Number.isNaN(date.valueOf());

	return isDateObj && isValidDate;
};

export const isSameMonth = (date: Date, baseDate = new Date()) => {
	if (!(isDate(date) && isDate(baseDate))) return false;

	const baseDateMonth = +baseDate.getMonth() + 1;
	const baseDateYear = baseDate.getFullYear();

	const dateMonth = +date.getMonth() + 1;
	const dateYear = date.getFullYear();

	return +baseDateMonth === +dateMonth && +baseDateYear === +dateYear;
};

export const isSameDay = (date: Date, baseDate = new Date()) => {
	if (!(isDate(date) && isDate(baseDate))) return false;

	const baseDateDate = baseDate.getDate();
	const baseDateMonth = +baseDate.getMonth() + 1;
	const baseDateYear = baseDate.getFullYear();

	const dateDate = date.getDate();
	const dateMonth = +date.getMonth() + 1;
	const dateYear = date.getFullYear();

	return +baseDateDate === +dateDate && +baseDateMonth === +dateMonth && +baseDateYear === +dateYear;
};

export const convertToDateString = (dateString: string) => {
	//  Convert a "dd/MM/yyyy" into "yyyy/MM/dd"
	const d = dateString.split('/');
	const dat = d[2] + '-' + d[1] + '-' + d[0];
	return dat;
};

// export const getDateStringAu = (date = new Date()) => {
// 	if (!isDate(date)) return null;

// 	return [date.getFullYear(), zeroPad(+date.getDate(), 2), zeroPad(+date.getMonth() + 1, 2)].join('-');
// };

export const getDateString = (date = new Date()) => {
	if (!isDate(date)) return null;

	return [zeroPad(+date.getMonth() + 1, 2), zeroPad(+date.getDate(), 2), date.getFullYear()].join('-');
};

export const getDateStringYMD = (date = new Date()) => {
	if (!isDate(date)) return null;

	return [date.getFullYear(), zeroPad(+date.getMonth() + 1, 2), zeroPad(+date.getDate(), 2)].join('-');
};

export const getTimeString = (date = new Date()) => {
	if (!isDate(date)) return null;

	return date.toLocaleTimeString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
	});
};

export const getDateISO = (date = new Date()) => {
	if (!isDate(date)) return null;

	return [date.getFullYear(), zeroPad(+date.getMonth() + 1, 2), zeroPad(+date.getDate(), 2)].join('-');
};

export const getTimestamp = (date: (string | number)[]) =>
	new Date(`${date[2]}-${date[0]}-${date[1]}`)
		? new Date(`${date[2]}-${date[0]}-${date[1]}T01:00:00`).getTime()
		: null;

export const getPreviousMonth = (month: number, year: number) => {
	const prevMonth = month > 1 ? month - 1 : 12;
	const prevMonthYear = month > 1 ? year : year - 1;

	return { month: prevMonth, year: prevMonthYear };
};

export const getNextMonth = (month: number, year: number) => {
	const nextMonth = month < 12 ? month + 1 : 1;
	const nextMonthYear = month < 12 ? year : year + 1;

	return { month: nextMonth, year: nextMonthYear };
};

export const getDatesBetweenDates = (startDate: Date, endDate: Date) => {
	let dates: Date[] = [];

	const theDate = new Date(startDate);

	while (theDate < endDate) {
		dates = [...dates, new Date(theDate)];
		theDate.setDate(theDate.getDate() + 1);
	}
	return dates;
};

export const getDateFromNow = (editedDays: number) => {
	const today = new Date();
	const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + editedDays);
	return nextweek;
};

type IMonth = string | number;

type IDay = string | number;

type IYear = string | number;

type IDateArr = [IMonth, IDay, IYear];

export default (month = THIS_MONTH, year = THIS_YEAR) => {
	const monthDays = getMonthDays(month, year);
	const monthFirstDay = getMonthFirstDay(month, year);

	const daysFromPrevMonth = monthFirstDay - 1;
	const daysFromNextMonth =
		CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays) >= 7
			? (CALENDAR_WEEKS - 1) * 7 - (daysFromPrevMonth + monthDays)
			: CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays);

	const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year);
	const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);

	const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);

	const prevMonthDates: IDateArr[] = [...new Array(daysFromPrevMonth)].map((n, index) => {
		const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
		return [zeroPad(prevMonth, 2), zeroPad(day, 2), prevMonthYear];
	});

	const thisMonthDates: IDateArr[] = [...new Array(monthDays)].map((n, index) => {
		const day = index + 1;
		return [zeroPad(month, 2), zeroPad(day, 2), year];
	});

	const nextMonthDates: IDateArr[] = [...new Array(daysFromNextMonth)].map((n, index) => {
		const day = index + 1;
		return [zeroPad(nextMonth, 2), zeroPad(day, 2), nextMonthYear];
	});

	return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};
