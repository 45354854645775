import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { PopularLink } from 'apiFolder/directus-main';

interface IPopularLinks {
	links: PopularLink[];
}

const PopularLinksContainer: React.FC<IPopularLinks> = ({ links }) => {
	return (
		<Block>
			{links.length > 0 &&
				links.map((popularLink) => (
					<Link key={popularLink.link} to={popularLink.link}>
						<Item>{popularLink.link_text}</Item>
					</Link>
				))}
		</Block>
	);
};

const Block = styled.ul`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 35px 0 11px;
	& a {
		width: fit-content;
	}
`;

const Item = styled.li`
	color: ${({ theme }) => theme.palette.main};
	cursor: pointer;
	transition: all 0.3s ease;
	margin: 5px 0;

	&:hover {
		color: ${({ theme }) => theme.palette.purp};
	}
`;

export default PopularLinksContainer;
