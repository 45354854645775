import React from 'react';
import styled from 'styled-components';

import phoneIcone from 'images/icons/phoneIcon.svg';

const ModalFooter: React.FC<{ isOtp?: boolean; isActive?: boolean }> = ({ isOtp = false, isActive = false }) => {
	return (
		<Footer>
			<Wrapper>
				<Inner>
					<Row>
						<Text>Need help?</Text>
						<IconBox>
							<img src={phoneIcone} alt='phone' />
						</IconBox>
						<Text>
							Call
							<a href='tel:+610290686658'>(02) 9068 6658</a>
						</Text>
					</Row>
					<Button type='submit' disabled={isOtp && !isActive ? true : false}>
						{isOtp ? 'Confirm code' : 'Confirm Booking'}
					</Button>
				</Inner>
			</Wrapper>
		</Footer>
	);
};

const Footer = styled.footer`
	width: 100%;
	background-color: ${({ theme }) => theme.palette.white};

	& a {
		margin-left: 4px;
	}
`;

const Wrapper = styled.div`
	border-top: 1px solid ${({ theme }) => theme.palette.inactive};
`;

const Inner = styled.div`
	max-width: 928px;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 26px 24px;
	margin: 0 auto;

	@media (max-width: 767px) {
		flex-direction: column;
		padding: 20px 16px 32px;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: 767px) {
		order: 2;
		margin: 34px 0px 0px;
	}
`;

const IconBox = styled.div`
	padding: 0 8px 0 14px;

	@media (max-width: 330px) {
		padding: 0 4px 0 8px;
	}
`;

const Text = styled.p`
	font-size: 16px;
	font-weight: 400;
	color: ${({ theme }) => theme.palette.grey_text};
`;

const Button = styled.button`
	width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	background: #4544f5;
	font-family: 'MuseoSans';
	font-size: 18px;
	font-weight: 400;
	color: ${({ theme }) => theme.palette.white};
	border: 1px solid #4544f5;
	border-radius: 4px;
	cursor: pointer;
	padding: 20px 30px;
	margin-left: 20px;
	transition: all 0.3s ease;

	&:hover {
		color: ${({ theme }) => theme.palette.main};
		background: ${({ theme }) => theme.palette.white};
		border: 1px solid ${({ theme }) => theme.palette.inactive};
	}

	@media (max-width: 767px) {
		order: 1;
		width: 100%;
		padding: 10px 30px;
	}

	&:disabled {
		background: ${({ theme }) => theme.palette.bg_purp};
		cursor: default;
		color: ${({ theme }) => theme.palette.inactive};
		border: 1px solid #4544f5;
	}
`;

export default ModalFooter;
