import React from 'react';
import styled from 'styled-components';

import iconSrc from 'images/icons/searchIconPurp.svg';

const InputLoader: React.FC = () => {
	return (
		<InputWrap>
			<Text>Search by Suburb</Text>
		</InputWrap>
	);
};

const InputWrap = styled.div`
	width: 396px;
	min-height: 48px;
	background: #ffffff;
	border: 2px solid #424bec;
	box-sizing: border-box;
	box-shadow: 0px 0px 10px 2px rgba(66, 75, 236, 0.18);
	border-radius: 3px;
	background-color: ${({ theme }) => theme.palette.white};
	@media (max-width: 1280px) {
		width: 382px;
	}
	@media (max-width: 1080px) {
		width: 100%;
		max-width: 382px;
	}
	@media (max-width: 767px) {
		max-width: 100%;
	}
`;

const Text = styled.div`
	font-family: MuseoSans;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: ${({ theme }) => theme.palette.purp};

	display: flex;
	flex-wrap: nowrap;
	padding: 9px 0px 8px 22px;
	background: #ffffff;
	outline: none;
	transition: 0.3s ease-in;
	boxshadow: none;

	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 2px;
		right: 0;
		width: 14px;
		height: 100%;
		background: url(${iconSrc}) no-repeat center / contain;
		background-size: 14px 14px;
		margin-left: 10px;
		margin-right: 22px;
		transition: all 0.3s ease;
		@media (max-width: 767px) {
			margin-right: 17px;
		}
	}
`;

export default InputLoader;
