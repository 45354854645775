import React from 'react';
import { geolocated, GeolocatedProps } from 'react-geolocated';
import styled from 'styled-components';

import autocompleteIcon from 'images/icons/locationPointIcon.svg';

interface IGeoProps {
	label: string;
	userLocation: (userCoord: { latitude: number; longitude: number }, userTimestamp: number) => void;
	blurAsync: () => void;
	setIsLocationAccepted: (key: boolean) => void;
}

class Geolocation extends React.Component<IGeoProps & GeolocatedProps> {
	constructor(props) {
		super(props);
		this.handleCurrentLocation = this.handleCurrentLocation.bind(this);
		this.state = {
			coordinates: null,
			timestamp: null,
		};
	}

	componentDidMount() {
		this.props.setIsLocationAccepted(this.props.isGeolocationEnabled || false);
	}

	componentDidUpdate(prevProps) {
		this.props.setIsLocationAccepted(this.props.isGeolocationEnabled || false);
		if (prevProps !== this.props && this.props.coords && this.props.coords !== null) {
			this.setState({
				...this.state,
				coordinates: this.props.coords,
				timestamp: this.props.timestamp,
			});
		}
	}

	handleCurrentLocation() {
		if (this.state.coordinates) {
			this.props.blurAsync();
			this.props.userLocation(this.state.coordinates, this.state.timestamp || 10000000000);
		}
	}

	render(): JSX.Element {
		return !this.props.isGeolocationAvailable ? (
			<Text>Your browser does not support Geolocation</Text>
		) : !this.props.isGeolocationEnabled ? (
			<Text>Your geolocation is not enabled</Text>
		) : this.props.coords ? (
			<Geo isCoordinates={this.props.coords} onClick={() => this.handleCurrentLocation()}>
				<ImgBox>
					<img src={autocompleteIcon} alt='point' />
				</ImgBox>
				<Text>Use Current Location</Text>
			</Geo>
		) : (
			<Text>Getting the location data&hellip; </Text>
		);
	}
}

const Geo = styled.div<{
	isCoordinates: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	color: #000;
	background: #deebff;
	padding: 9px 0px 9px 24px;
	margin-left: -24px;
	margin-right: -15px;
	margin-top: -10px;
	margin-bottom: -10px;

	&:hover {
		cursor: ${({ isCoordinates }) => isCoordinates && 'pointer'};
	}
`;
const Text = styled.p`
	color: inherit;
`;

const ImgBox = styled.div`
	width: 20px;
	height: 20px;
	margin-right: 10px;
	color: inherit;
	& > img {
		object-fit: contain;
	}
`;

export default geolocated({
	positionOptions: {
		enableHighAccuracy: false,
	},
	userDecisionTimeout: 5000,
	isOptimisticGeolocationEnabled: true,
})(Geolocation);
