import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import calendar, {
	WEEK_DAYS,
	CALENDAR_MONTHS,
	getDateString,
	getPreviousMonth,
	getNextMonth,
	getTimestamp,
} from 'helpers/calendar';

import DateBox from 'components/common/calendar/DateBox';
import ArrowIcon from 'components/common/calendar/CalendarArrow';

interface IProps {
	pickedDate?: Date | null;
	onPick: (date: (string | number)[]) => void;
	minDate?: number;
	isOnTheLeft?: boolean;
	curRef?: React.MutableRefObject<HTMLDivElement | null>;
	isUnder: boolean;
	isStart: boolean;
}

const Calendar: React.FC<IProps> = ({
	pickedDate,
	onPick,
	minDate = 0,
	isOnTheLeft = false,
	curRef,
	isUnder,
	isStart,
}) => {
	const [chosenDateArr, setChosenDateArr] = useState<(string | number)[] | null>(null);
	const [displayedMonth, setDisplayedMonth] = useState<number | null>(null);
	const [displayedYear, setDisplayedYear] = useState<number | null>(null);
	const [calendarDates, setCalendarDates] = useState<(string | number)[][]>([]);

	useEffect(() => {
		const newDateArr = pickedDate
			? getDateString(pickedDate)!.split('-')
			: minDate > 0
			? getDateString(new Date(minDate))!.split('-')
			: getDateString(new Date())!.split('-');
		setChosenDateArr(newDateArr);
		setDisplayedMonth(+newDateArr[0]);
		setDisplayedYear(+newDateArr[2]);
		setCalendarDates(calendar(+newDateArr[0], +newDateArr[2]));
	}, []);

	useEffect(() => {
		const newDateArr = pickedDate
			? getDateString(pickedDate)!.split('-')
			: minDate > 0
			? getDateString(new Date(minDate))!.split('-')
			: getDateString(new Date())!.split('-');
		setChosenDateArr(newDateArr);
		setDisplayedMonth(+newDateArr[0]);
		setDisplayedYear(+newDateArr[2]);
		setCalendarDates(calendar(+newDateArr[0], +newDateArr[2]));
	}, [pickedDate]);

	const pickDateClickHandler = (date: (string | number)[]) => {
		onPick(date);
		setChosenDateArr(date);
		setDisplayedMonth(+date[0]);
		setDisplayedYear(+date[2]);
		setCalendarDates(calendar(+date[0], +date[2]));
	};

	const showPrevMonthClickHandler = () => {
		const { month: prevMonth, year: prevYear } = getPreviousMonth(displayedMonth!, displayedYear!);
		setDisplayedMonth(prevMonth);
		setDisplayedYear(prevYear);
		setCalendarDates(calendar(prevMonth, prevYear));
	};

	const showNextMonthClickHandler = () => {
		const { month: nextMonth, year: nextYear } = getNextMonth(displayedMonth!, displayedYear!);
		setDisplayedMonth(nextMonth);
		setDisplayedYear(nextYear);
		setCalendarDates(calendar(nextMonth, nextYear));
	};

	return (
		chosenDateArr && (
			<CalendarContainer isOnTheLeft={isOnTheLeft} ref={curRef} isUnder={isUnder} isStart={isStart}>
				<Header>
					<Month>{`${CALENDAR_MONTHS[displayedMonth!]} ${displayedYear!}`}</Month>
					<Controls>
						<Control onClick={showPrevMonthClickHandler}>
							<ArrowIcon color='#111111' />
						</Control>
						<ControlRight onClick={showNextMonthClickHandler}>
							<ArrowIcon color='#111111' />
						</ControlRight>
					</Controls>
				</Header>
				<DatesWithDays>
					<WeekDays>
						{Object.values(WEEK_DAYS).map((day) => (
							<WeekDay key={day}>{day}</WeekDay>
						))}
					</WeekDays>
					<Dates>
						{calendarDates.map((date) => (
							<DateBox
								key={date.join('')}
								date={date}
								onPick={pickDateClickHandler}
								isActive={chosenDateArr.join('') === date.join('')}
								isOtherMonth={new Date(date).getTime() < new Date().getTime()}
								disable={getTimestamp(date)! < minDate}
							/>
						))}
					</Dates>
				</DatesWithDays>
			</CalendarContainer>
		)
	);
};

const CalendarContainer = styled.div<{
	isOnTheLeft: boolean;
	isUnder: boolean;
	isStart: boolean;
}>`
	position: absolute;
	left: ${({ isOnTheLeft }) => isOnTheLeft && 0};
	right: ${({ isOnTheLeft }) => !isOnTheLeft && 0};
	top: ${({ isUnder }) => !isUnder && 'calc(100% + 8px)'};
	bottom: ${({ isUnder }) => isUnder && 'calc(100% - 22px)'};
	width: 280px;
	padding: 30px 35px;
	border-radius: 4px;
	background: ${({ theme }) => theme.palette.white};
	border-radius: 10px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
	z-index: 10;

	@media (max-width: 1280px) {
		right: 20px;
	}

	@media (max-width: 767px) {
		left: ${({ isStart }) => isStart && 0};
		right: ${({ isStart }) => isStart && 'none'};
	}

	@media (max-width: 420px) {
		position: fixed;
		left: 0;
		top: calc(100vh - 520px);
		max-width: 414px;
		width: 100%;
		height: 520px;
		background-color: white;
		padding: 43px 20px 20px;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
	}
`;

const Header = styled.div`
	margin-bottom: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 420px) {
		margin-bottom: 60px;
		position: relative;
	}
`;

const Month = styled.h2`
	margin: 0;
	padding: 0;
	font-family: 'MuseoSans', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #595959;

	@media (max-width: 420px) {
		font-weight: 600 !important;
		font-size: 18px !important;
		line-height: 22px;
		color: #000;
		margin: 0 auto;
	}
`;

const Controls = styled.div`
	width: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 420px) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
`;

const Control = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	& svg {
		fill: ${({ theme }) => theme.palette.main};
	}

	@media (min-width: 768px) {
		svg {
			&:hover {
				fill: ${({ theme }) => theme.palette.purp};
			}
		}
	}
`;

const ControlRight = styled(Control)`
	transform: rotate(180deg);
`;

const DatesWithDays = styled.div`
	width: 100%;
	@media (max-width: 420px) {
		width: 335px;
		margin: 0 auto;
	}
`;

const WeekDays = styled.div`
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	@media (max-width: 420px) {
		justify-content: space-around;
		margin-bottom: 25px;
	}
`;

const WeekDay = styled.p`
	width: 30px;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${({ theme }) => theme.palette.main};

	@media (max-width: 420px) {
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
	}
`;

const Dates = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	@media (max-width: 420px) {
		justify-content: space-between;
	}
`;

export default Calendar;
