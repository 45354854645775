import React, { useState } from 'react';
import styled from 'styled-components';

import { IFaq } from './PageInfoBlock';

const FaqContainer: React.FC<IFaq> = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<FaqItem isOpen={isOpen}>
			<FaqTitle onClick={() => setIsOpen(!isOpen)}>{question}</FaqTitle>
			<FaqText isOpen={isOpen}>{answer}</FaqText>
		</FaqItem>
	);
};

const FaqItem = styled.div<{ isOpen: boolean }>`
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	box-shadow: ${({ isOpen }) => (isOpen ? '0px 1px 3px rgba(0, 0, 0, 0.25)' : null)};
	margin: ${({ isOpen }) => (isOpen ? '16px 0' : 0)};
	transition: all 0.3s ease;
`;
const FaqTitle = styled.h3`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	width: 100%;
	font-family: MuseoSans;
	font-style: normal;
	font-weight: 300 !important;
	font-size: 16px;
	line-height: 23px;
	color: #000000;
	padding: 16px;
	cursor: pointer;
`;
const FaqText = styled.p<{ isOpen: boolean }>`
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
	width: 100%;
	font-family: MuseoSans;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: #000000;
	padding: 12px 16px 16px;
	transition: all 0.3s ease;
`;

export default FaqContainer;
