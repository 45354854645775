import React from 'react';
import styled from 'styled-components';

import { IFaq } from './PageInfoBlock';
import FaqContainer from './Faq';

interface IFaqs {
	faqs: IFaq[];
}
const FaqsContainer: React.FC<IFaqs> = ({ faqs }) => {
	return (
		<FaqsBlock>
			{faqs.length > 0 &&
				faqs.map((faq) => (
					<FaqContainer key={faq.question.slice(2, 7)} question={faq.question} answer={faq.answer} />
				))}
		</FaqsBlock>
	);
};

const FaqsBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 35px 0 11px;

	& > div {
		transition: all 0.3s ease;
	}
	& > div:first-child {
		margin-top: 0;
	}
`;

export default FaqsContainer;
