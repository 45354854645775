import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import TabContainer from 'components/booking/TabContainer';
import { IAvailabilityPageInformation, SuburbData } from 'apiFolder/directus-main';

import Posts from 'components/booking/Posts';
import FaqsContainer from 'components/booking/Faqs';
import Nearby from 'components/booking/Nearby';
import PopularLinksContainer from './PopularLinks';

export interface IPost {
	content: string;
	postcode: string;
}

export interface IFaq {
	question: string;
	answer: string;
}

interface IPageInfo {
	item?: SuburbData;
	pageInfo?: IAvailabilityPageInformation;
}

const PageInfoBlock: React.FC<IPageInfo> = ({ item, pageInfo }) => {
	const [tabs, setTabs] = useState([
		{ id: 1, isActive: true },
		{ id: 2, isActive: false },
		{ id: 3, isActive: false },
		{ id: 4, isActive: false },
		{ id: 5, isActive: false },
	]);
	const [activeTabNumber, setActiveTabNumber] = useState(1);
	const posts = (item?.posts as IPost[]) || pageInfo?.posts || [];
	const about = item?.about_bookphysio || pageInfo?.about_bookphysio || '';
	const faqs = item?.faqs || pageInfo?.faqs || [];
	const popularLinks = pageInfo?.popular_links || [];
	const name = item?.name || '';

	const handleClick = (num: number) => {
		setTabs(
			tabs.map((tab) => {
				if (num === tab.id) {
					setActiveTabNumber(num);
					tab.isActive = true;
					return tab;
				}
				tab.isActive = false;
				return tab;
			}),
		);
	};

	return (
		<TabContainer text='Page Information' isTabOpen>
			<TabBox>
				<Row>
					<Title isActive={tabs[0].isActive} onClick={() => handleClick(1)}>
						About BookPhysio.com
					</Title>
					<Title isActive={tabs[1].isActive} onClick={() => handleClick(2)}>
						Patient posts
					</Title>
					<Title isActive={tabs[2].isActive} onClick={() => handleClick(3)}>
						FAQs
					</Title>
					{pageInfo && (
						<Title isActive={tabs[3].isActive} onClick={() => handleClick(4)}>
							Popular Links
						</Title>
					)}
					{item && (
						<Title isActive={tabs[3].isActive} onClick={() => handleClick(4)}>
							Nearby Suburbs
						</Title>
					)}
					<Title isActive={tabs[4].isActive} onClick={() => handleClick(5)}>
						{`About ${name}`}
					</Title>
				</Row>
				<RowLine />
			</TabBox>
			<TextBlock>
				<TextPar isActive={activeTabNumber === 1}>{about}</TextPar>
				<TextPar isActive={activeTabNumber === 2}>
					<Posts posts={posts} />
				</TextPar>
				<TextPar isActive={activeTabNumber === 3}>
					<FaqsContainer faqs={faqs} />
				</TextPar>
				{item && (
					<TextPar isActive={activeTabNumber === 4}>
						<Nearby item={item} />
					</TextPar>
				)}
				{pageInfo && (
					<TextPar isActive={activeTabNumber === 4}>
						<PopularLinksContainer links={popularLinks} />
					</TextPar>
				)}
				<TextPar isActive={activeTabNumber === 5}>
					{item
						? item.city.link && (
								<AboutClinicMark>
									Associated City: <Link to={item.city?.link}>{item.city?.text}</Link>
								</AboutClinicMark>
						  )
						: null}
					{item
						? item.state.link && (
								<AboutClinicMark>
									Associated state: <Link to={item.state?.link}>{item.state?.text}</Link>
								</AboutClinicMark>
						  )
						: null}
				</TextPar>
			</TextBlock>
		</TabContainer>
	);
};

const TabBox = styled.div`
	position: relative;
	overflow-y: hidden;
	@media (max-width: 1225px) {
		max-width: 366px;
		overflow-x: auto;
	}
	@media (max-width: 670px) {
		max-width: calc(100vw - 50px);
	}
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: MuseoSans;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
`;

const RowLine = styled.div`
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	width: inherit;
	height: 2px;
	background: ${({ theme }) => theme.palette.inactiveTabLine};
`;

const Title = styled.h6<{ isActive: boolean }>`
	height: 100%;
	font-family: MuseoSans;

	font-style: normal;
	font-weight: 400 !important;
	font-size: 16px !important;
	color: ${({ theme, isActive }) => (isActive ? theme.palette.main : theme.palette.inactiveTab)};
	padding: 25px 10px 17px;
	border-bottom: 2px solid ${({ theme, isActive }) => (isActive ? theme.palette.main : 'transparent')};
	cursor: pointer;
	position: relative;
	z-index: 1;

	@media (max-width: 1225px) {
		&:not(:last-child) {
			margin-right: 78px;
		}
	}
`;

const TextBlock = styled.div``;

const TextPar = styled.div<{ isActive: boolean }>`
	transition: all 0.3s ease;

	${({ isActive }) =>
		!isActive &&
		css`
			display: none;
		`}

	${({ isActive }) =>
		isActive &&
		css`
			display: block;
			font-family: 'MuseoSans', sans-serif;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 23px;
			margin-top: 24px;
		`}
`;
export const AboutClinicMark = styled.div`
	font-weight: 500;
	margin: 5px 0;
	& a {
		font-weight: 300;
		text-decoration: none;
		color: #0c1f3f;
		transition: all 0.3s ease;
		&:hover {
			color: #424bec;
		}
	}
`;
export default PageInfoBlock;
