import React from 'react';
import styled from 'styled-components';

import closeIcon from 'images/icons/crossIcon.svg';

interface IModalHeader {
	onCloseModal: (key: boolean) => void;
}

const ModalHeader: React.FC<IModalHeader> = ({ onCloseModal }) => {
	const handleModalClose = () => {
		onCloseModal(false);
	};

	return (
		<Header>
			<Wrapper>
				<Inner>
					<Title>Booking Confirmation</Title>
					<CloseIcon onClick={handleModalClose}>
						<img src={closeIcon} alt='close' />
					</CloseIcon>
				</Inner>
			</Wrapper>
		</Header>
	);
};

const Header = styled.div`
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	background-color: ${({ theme }) => theme.palette.white};
	z-index: 100;
`;

const Wrapper = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.palette.inactive};
`;

const Inner = styled.div`
	max-width: 928px;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 36px 24px;
	margin: 0 auto;
	@media (max-width: 767px) {
		padding: 20px 25px 20px 16px;
	}
`;

const Title = styled.h2`
	font-family: 'SharpGrotesk-MediumNo21';
	font-weight: 400 !important;
	font-size: 22px !important;
	margin-right: 50px;
	@media (max-width: 767px) {
		font-size: 16px !important;
	}
`;
const CloseIcon = styled.div`
	width: 25px;
	height: 25px;
	position: relative;
	cursor: pointer;

	& > img,
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		text-align: right;
		transition: all 0.5s ease;

		@media (min-width: 767px) {
			&:hover {
				transform: scale(1.2);
			}
		}

		@media (max-width: 767px) {
			width: 20px;
			height: 20px;
		}
	}
`;

export default ModalHeader;
