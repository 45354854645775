import React, { useState } from 'react';
import styled from 'styled-components';

import { IAppointmentDate } from './Main';

import AppointmentContainer from 'components/booking/Appointment';

interface IAppointments {
	isLoading: boolean;
	appointment: IAppointmentDate;
	appointments: IAppointmentDate[];
	onDateCellClick: (key: IAppointmentDate) => void;
	setIsOnTop: (key: boolean) => void;
}

const AppointmentsContainer: React.FC<IAppointments> = ({
	appointment,
	appointments,
	onDateCellClick,
	setIsOnTop,
	isLoading,
}) => {
	const [isShowHint, setIsShowHint] = useState<boolean>(true);

	return (
		<Container>
			<Title>Appointments by day</Title>
			{appointments.map((appoint, index) => (
				<AppointmentContainer
					key={appoint.date}
					index={index}
					onDateCellClick={onDateCellClick}
					appointment={appoint}
					curAppoint={appointment}
					setIsOnTop={setIsOnTop}
					isLoading={isLoading}
					setIsShowHint={setIsShowHint}
					isShowHint={isShowHint}
				/>
			))}
			<AppointmentContainer
				key='calendar'
				isIcon
				onDateCellClick={onDateCellClick}
				curAppoint={appointment}
				setIsOnTop={setIsOnTop}
				isLoading={isLoading}
				setIsShowHint={setIsShowHint}
			/>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const Title = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	margin-right: 18px;

	@media (max-width: 1023px) {
		display: none;
	}
`;

export default AppointmentsContainer;
