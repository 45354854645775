import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { GoogleApiWrapper } from 'google-maps-react';
import styled from 'styled-components';

import { ClinicCard, ISuburb } from 'templates/booking';
import SkeletonMapContainer from '../skeleton/SkeletonMapContainer';
import Marker from './Marker';

export const AUSTRALIA_CENTER = {
	lat: -28.6274942,
	lng: 134.2125589,
};

interface ILocation {
	lat: number;
	lng: number;
}

interface IMapContainer {
	clinics: ClinicCard[];
	suburb: ISuburb;
	activeClinic: ClinicCard;
	hoveredClinicCard: ClinicCard | null;
	setActiveClinic: (key: ClinicCard | null) => void;
}

const MapContainerReact: React.FC<IMapContainer> = ({
	clinics,
	suburb,
	activeClinic,
	hoveredClinicCard,
	setActiveClinic,
}) => {
	const [mapCenter, setMapCenter] = useState<ILocation>(suburb?.location || AUSTRALIA_CENTER);
	const [mapZoom, setMapZoom] = useState<number>(suburb ? 13 : 5);

	useEffect(() => {
		if (!suburb) {
			setMapCenter(AUSTRALIA_CENTER);
			setMapZoom(5);
			return;
		}
		if (suburb.location) {
			setMapCenter(suburb.location);
			setMapZoom(13);
		}
	}, [suburb]);

	const hadleMarkerClick = (clinic: ClinicCard) => {
		if (activeClinic && activeClinic.id === clinic.id) {
			setActiveClinic(null);

			if (suburb?.location || activeClinic?.location) {
				setMapCenter(suburb?.location || activeClinic?.location);
			}
			return;
		}

		setActiveClinic(clinic);
	};

	const hadleMapClick = (clickedItemId: string) => {
		if (activeClinic === null) return;

		const clickedMarker = clinics.filter((clinic) => clinic.id === +clickedItemId);
		if (clickedMarker.length > 0) return;

		setActiveClinic(null);
	};

	return (
		<Wrap>
			<GoogleMapReact
				bootstrapURLKeys={{
					key: `${process.env.GATSBY_GOOGLE_MAPS_API_KEY!}`,
					id: '__googleMapsScriptId',
					libraries: ['places'],
				}}
				defaultCenter={mapCenter}
				defaultZoom={mapZoom}
				yesIWantToUseGoogleMapApiInternals
				options={{
					mapTypeControl: true,
					streetViewControl: true,
					scaleControl: true,
				}}
				onClick={(value) => hadleMapClick(value.event.target.id)}
			>
				{clinics?.map(
					(clinic) =>
						clinic.location && (
							<Marker
								key={clinic.id}
								id={clinic.id.toString()}
								text={`${clinic.name}, ${clinic.full_address || ''}`}
								hadleMarkerClick={() => hadleMarkerClick(clinic)}
								lat={clinic.location?.lat}
								lng={clinic.location?.lng}
								item={clinic}
								isActiveMarker={clinic.id === activeClinic?.id || clinic.id === hoveredClinicCard?.id}
							/>
						),
				)}
			</GoogleMapReact>
		</Wrap>
	);
};

const Wrap = styled.div`
	width: 100%;
	height: calc(100vh - 150px);

	@media (max-width: 767px) {
		top: 185px;
		height: calc(100vh - 185px);

		[role='menubar'] {
			top: 55px !important;
		}
	}
`;

export default GoogleApiWrapper({
	apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY!,
	libraries: ['places'],
	LoadingContainer: SkeletonMapContainer,
})(MapContainerReact as IMapContainer);
