import { IOption } from 'components/booking/HeaderTop';

export const BookNowUrl = {
	url: '/book-now',
	suburb: 'suburb=',
};

const encodedUrl = (str: IOption) => {
	const suburb = { label: str.label, location: str.location, urlSlug: str.urlSlug };

	return `${BookNowUrl.url}?${BookNowUrl.suburb}${encodeURIComponent(JSON.stringify(suburb))}`;
};

export default encodedUrl;
