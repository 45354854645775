import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import useToggle from 'hooks/useToggle';
import { IAppointmentDate, IAppointmentDateData } from './Main';

import ModalHeader from 'components/booking/ModalHeader';
import Data from 'components/booking/Data';
import FormFields from 'components/booking/FormFields';
import ModalFooter from 'components/booking/ModalFooter';

import loader from 'images/spinner.svg';

export interface IModal {
	data: IAppointmentDateData;
	appoint: IAppointmentDate;
	onCloseModal: (key: boolean) => void;
	onConfirm: (key: string) => void;
	onOpenOTPModal: () => void;
	setOtpPhone: (key: string) => void;
	setAppointId: (key: string) => void;
	setIsSuccess: (key: boolean) => void;
}

interface IDataItem {
	value: string;
	isValid: boolean;
}
export interface IPatientData {
	firstName: IDataItem;
	lastName: IDataItem;
	email: IDataItem;
	birth: IDataItem;
	phone: IDataItem;
	gender: IDataItem;
	insurance: IDataItem;
	agree: boolean;
	remember: boolean;
}

const PatientDetailsModal: React.FC<IModal> = ({
	onConfirm,
	onCloseModal,
	onOpenOTPModal,
	data,
	appoint,
	setIsSuccess,
	setOtpPhone,
	setAppointId,
}) => {
	const [patient, setPatient] = useState<IPatientData>({
		firstName: { value: '', isValid: false },
		lastName: { value: '', isValid: false },
		email: { value: '', isValid: false },
		birth: { value: '', isValid: false },
		phone: { value: '', isValid: false },
		gender: { value: '', isValid: true },
		insurance: { value: '', isValid: true },
		agree: false,
		remember: true,
	});
	const [isSending, setIsSending] = useToggle(false);
	const [isAppear, setIsAppear] = useToggle(true);

	useEffect(() => {
		setIsAppear(true);
	}, []);

	const onSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		setIsSending(true);
	};

	const closeClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			onCloseModal(true);
		}
	};

	return (
		<Wrapper isAppear={isAppear} onClick={closeClickHandler}>
			{isSending && (
				<Loader>
					<img src={loader} alt='loader' />
				</Loader>
			)}
			<FormContentWrap onSubmit={onSubmit}>
				<ModalHeader onCloseModal={onCloseModal} />
				<Main>
					<DataContainer>
						<Inner>
							<TitleBlock>
								<Title>Booking Details</Title>
							</TitleBlock>
							<Data data={data} appoint={appoint} />
						</Inner>
					</DataContainer>
					<FormContainer>
						<InnerForm>
							<Title>Patient Details</Title>
							<FormFields
								onChangePatientData={setPatient}
								patient={patient}
								data={data}
								isSending={isSending}
								onSending={setIsSending}
								onCloseModal={onCloseModal}
								onConfirm={onConfirm}
								setOtpPhone={setOtpPhone}
								setAppointId={setAppointId}
								onOpenOTPModal={onOpenOTPModal}
								setIsSuccess={setIsSuccess}
							/>
						</InnerForm>
					</FormContainer>
				</Main>
				<ModalFooter />
			</FormContentWrap>
		</Wrapper>
	);
};

const Wrapper = styled.div<{ isAppear: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px;
	margin: 0 auto;
	background-color: ${({ theme }) => theme.palette.modal_bg};
	backdrop-filter: blur(2px);
	overflow: hidden;
	z-index: 200;

	animation-name: ${({ isAppear }) => isAppear && fadeIn};
	animation-duration: 0.4s;

	@media (max-width: 767px) {
		padding: 8px;
	}
	@media (max-width: 500px) {
		padding: 0px;
	}
`;

const FormContentWrap = styled.form`
	max-width: 1000px;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.bg_grey};
	margin: 0px auto;
	border-radius: 4px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;
const Main = styled.div`
	flex: 1 1 auto;
	width: 100%;
	overflow-y: auto;

	@media (min-width: 768px) {
		scrollbar-color: ${({ theme }) => theme.palette.items_border};
		scrollbar-width: none;

		&::-webkit-scrollbar {
			transition: all 0.4s ease-in;
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
			-webkit-box-shadow: inset 0 0 0 transparent;
			box-shadow: inset 0 0 0 transparent;
			margin: 0px 0;
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.palette.items_border};
			border-radius: 0px;
			outline: 2px solid transparent;
			height: 50%;
		}

		&:hover::-webkit-scrollbar-thumb {
			background: ${({ theme }) => theme.palette.bg};
		}
		&::-webkit-scrollbar-thumb:hover {
			background: ${({ theme }) => theme.palette.bg};
		}
	}
`;
const DataContainer = styled.div`
	width: 100%;
	padding: 40px 24px;
	background-color: ${({ theme }) => theme.palette.bg};
	@media (max-width: 767px) {
		padding: 20px 0;
	}
`;

const FormContainer = styled.div`
	width: 100%;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	padding: 40px 24px;
	background-color: ${({ theme }) => theme.palette.white};
	@media (max-width: 767px) {
		padding: 25px 16px 18px;
	}
`;
const Inner = styled.div`
	max-width: 880px;
	width: 100%;
	margin: 0px auto;
	border-radius: 4px;
	overflow: hidden;
`;
const InnerForm = styled(Inner)`
	padding-bottom: 100px;
`;

const TitleBlock = styled.div`
	@media (max-width: 767px) {
		margin: 0px 0 0 16px;
	}
`;
const Title = styled.p`
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: ${({ theme }) => theme.palette.main};
	margin: 0px 0 17px;
	@media (max-width: 767px) {
		padding: 0px;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		margin: 0px 0 20px;
	}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Loader = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 210;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background-color: ${({ theme }) => theme.palette.modal_bg};
	backdrop-filter: blur(2px);

	& > img {
		width: 200px;
		height: 200px;
		max-width: 100%;
		object-fit: contain;
	}
`;

export default PatientDetailsModal;
