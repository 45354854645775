import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { IAppointmentDate } from './Main';
import { getDateStringYMD, getTimestamp } from 'helpers/calendar';
import { PERIOD_IN_DAYS } from 'helpers/appointments';

import calendarIcon from 'images/icons/calendarIcon.svg';
import calendarHintIcon from 'images/icons/calendarHintIcon.svg';
import closeHintIcon from 'images/icons/crossWhiteIcon.svg';

import Calendar from 'components/common/calendar/Calendar';

interface IAppointment {
	appointment?: IAppointmentDate;
	curAppoint?: IAppointmentDate;
	index?: number;
	isIcon?: boolean;
	isShowHint?: boolean;
	isLoading: boolean;
	onDateCellClick: (key: IAppointmentDate) => void;
	setIsOnTop: (key: boolean) => void;
	setIsShowHint: (key: boolean) => void;
}

const AppointmentContainer: React.FC<IAppointment> = ({
	appointment,
	curAppoint,
	index,
	isIcon,
	isLoading,
	onDateCellClick,
	setIsOnTop,
	setIsShowHint,
	isShowHint,
}) => {
	const [isAct, setIsAct] = useState(appointment?.isActive || false);
	const [dateText, setDateText] = useState(appointment?.date);
	const [showCalendar, setShowCalendar] = useState(false);

	const now = new Date();
	const minDate = new Date(now.setDate(new Date(now).getDate() - 1)).getTime();

	useEffect(() => {
		if (isIcon) setDateText('More');
	}, []);

	useEffect(() => {
		const lastDate = new Date();
		const curDate = new Date(curAppoint!.timeString);
		lastDate.setDate(lastDate.getDate() + PERIOD_IN_DAYS);
		lastDate.setHours(0, 0, 0, 0);

		setIsAct(
			appointment?.timeString === curAppoint?.timeString ||
				(lastDate.getTime() <= curDate.getTime() && isIcon) ||
				false,
		);

		if (lastDate.getTime() > curDate.getTime() && isIcon) {
			setDateText('More');
		}
	}, [curAppoint]);

	const getDateWithComma = (dateString: string) => {
		if (dateString) {
			return dateString.replace(/ /gi, ', ');
		}
	};

	const handleClick = () => {
		setIsShowHint(false);

		if (isLoading) return;

		if (isIcon && !showCalendar) {
			setShowCalendar(true);
			setIsOnTop(true);
		}

		if (isIcon) {
			return;
		}

		onDateCellClick({
			...appointment!,
			isActive: true,
		});
	};

	const onPick = (dateItem: (string | number)[]) => {
		const selectedTimestamp = getTimestamp(dateItem);
		const selectedDate = new Date(selectedTimestamp!);
		const selectedApointmentDate = {
			date: `${selectedDate.toLocaleString('en-Au', { weekday: 'short', day: 'numeric' })}`,
			timeString: getDateStringYMD(new Date(selectedTimestamp!)),
			isActive: !isAct,
		} as IAppointmentDate;
		onDateCellClick(selectedApointmentDate);
		setDateText(selectedApointmentDate.date);
		setShowCalendar(false);
		setIsOnTop(false);
	};

	const handleCloseCalendar = () => {
		setShowCalendar(false);
		setIsOnTop(false);
	};

	return (
		<>
			<Container
				isActive={isAct}
				id={appointment?.date || 'calendar'}
				isLoading={isLoading}
				onClick={handleClick}
				isHintBorder={index === 2 && isShowHint!}
			>
				{isIcon && !isAct && (
					<CalendarIcon>
						<img src={calendarIcon} alt='calendar' />
					</CalendarIcon>
				)}
				<CalendarText isHintBorder={index === 2 && isShowHint!}>{getDateWithComma(dateText!)}</CalendarText>
				{showCalendar && (
					<>
						<Calendar
							onPick={onPick}
							isUnder={false}
							isStart={false}
							minDate={minDate}
							pickedDate={new Date()}
						/>
						<CalendarUnderLayer isActive={isAct} onClick={handleCloseCalendar} />
					</>
				)}
			</Container>
			{index === 2 && isShowHint && (
				<Hint onClick={() => setIsShowHint(false)}>
					<HintIcon>
						<img src={calendarHintIcon} alt='hint' />
					</HintIcon>
					<HintText>Change days here</HintText>
					<HintCloseIcon>
						<img src={closeHintIcon} alt='close hint' />
					</HintCloseIcon>
				</Hint>
			)}
		</>
	);
};

const Container = styled.div<{ isActive: boolean; isLoading: boolean; isHintBorder: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: ${({ theme, isActive }) => (isActive ? theme.palette.white : theme.palette.main)};
	background: ${({ theme, isActive }) => (isActive ? theme.palette.purp : theme.palette.white)};
	border: 1px solid
		${({ theme, isActive, isHintBorder }) =>
			isHintBorder || isActive ? theme.palette.purp : theme.palette.inactive};
	box-sizing: border-box;
	border-radius: 20px;
	padding: 11px 18px;
	margin: 0 4px;
	white-space: nowrap;
	text-transform: capitalize;
	cursor: ${({ isLoading }) => (isLoading ? 'auto' : 'pointer')};
	transition: all 0.3s ease;

	@media (min-width: 1367px) {
		&:hover {
			border: 1px solid
				${({ theme, isActive, isLoading }) =>
					isLoading ? 'none' : isActive ? theme.palette.inactive : theme.palette.purp};
		}
	}
	@media (max-width: 1280px) {
		position: static;
	}
`;

const CalendarUnderLayer = styled.div<{ isActive: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	background: transparent;
	cursor: initial;

	@media (max-width: 420px) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
	}
`;

const CalendarIcon = styled.div`
	position: relative;
	width: 14px;
	height: 14px;
	margin-right: 5px;

	& > img,
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

const CalendarText = styled.div<{ isHintBorder: boolean }>`
	${({ isHintBorder }) =>
		isHintBorder &&
		css`
			@media (min-width: 830px) {
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 30px;
					left: 50%;
					transform: translateX(-50%);
					width: 0;
					height: 0;
					border-left: 9px solid transparent;
					border-right: 9px solid transparent;

					border-bottom: 9px solid #333954;
				}
			}
		`}
`;

const Hint = styled.div`
	position: absolute;
	top: 92%;
	left: 417px;
	display: flex;
	align-items: center;

	padding: 10px 30px 10px 12px;
	background: #333954;
	box-shadow: 3px 5px 5px #0000001c;
	border-radius: 3px;
	z-index: 12;
	cursor: pointer;

	@media (max-width: 830px) {
		&:before {
			content: '';
			position: absolute;
			top: -8px;
			left: 10%;
			width: 0;
			height: 0;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;

			border-bottom: 9px solid #333954;
		}
	}
	@media (max-width: 500px) {
		&:before {
			left: 80%;
		}
	}

	@media (max-width: 1023px) {
		left: 242px;
	}
	@media (max-width: 767px) {
		top: 97%;
	}
	@media (max-width: 500px) {
		left: 90px;
	}
`;
const HintText = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: ${({ theme }) => theme.palette.white};
	text-transform: none;
	letter-spacing: 0.4px;
	margin-top: 4px;
`;
const HintIcon = styled.div`
	width: 24px;
	height: 24px;
	margin-right: 8px;

	& > svg,
	img {
		object-fit: contain;
	}
`;
const HintCloseIcon = styled.div`
	position: relative;
	top: -5px;
	right: -20px;
	width: 15px;
	height: 15px;

	& > svg,
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export default AppointmentContainer;
