import React, { useEffect, RefObject } from 'react';
import styled, { css } from 'styled-components';

import useToggle from 'hooks/useToggle';

import checkedIcon from 'images/icons/checkedIcon.svg';
import uncheckedIcon from 'images/icons/uncheckedIcon.svg';
import uncheckedErrorIcon from 'images/icons/uncheckedErrorIcon.svg';

interface IProps {
	id: string;
	checkboxRef?: RefObject<HTMLInputElement>;
	onCheck: (e: React.MouseEvent<HTMLDivElement>) => void;
	onClickInactive?: (e: React.MouseEvent<HTMLDivElement>) => void;
	isChecked?: boolean;
	noMargins?: boolean;
	inactive?: boolean;
	isChangedIAgree?: boolean;
	onLabelClick?: () => void;
}

const Checkbox: React.FC<IProps> = ({
	id,
	checkboxRef = null,
	onCheck,
	onClickInactive = () => {},
	children,
	isChecked = false,
	noMargins = false,
	inactive = false,
	isChangedIAgree,
	onLabelClick = null,
}) => {
	const [checked, setChecked] = useToggle(isChecked);

	const handleCheck = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!inactive) {
			onCheck(e);
			setChecked(e);
			return;
		}
		onClickInactive(e);
	};

	useEffect(() => {
		if (checked !== isChecked) {
			setChecked(isChecked);
		}
	}, [isChecked]);

	return (
		<CheckboxLabel id={id} noMargins={noMargins} ref={checkboxRef}>
			<CheckboxContainer onClick={handleCheck}>
				<CustomCheckbox isChecked={checked} isInactive={inactive}>
					<CheckIcon
						isChecked={checked}
						src={checked ? checkedIcon : isChangedIAgree ? uncheckedErrorIcon : uncheckedIcon}
					/>
				</CustomCheckbox>
			</CheckboxContainer>
			<Label isInactive={inactive} onClick={onLabelClick || handleCheck}>
				{children}
			</Label>
		</CheckboxLabel>
	);
};

const CheckboxLabel = styled.div<{ noMargins: boolean }>`
	width: 100%;
	display: flex;
	align-items: center;
	margin: ${({ noMargins }) => (noMargins ? '0' : '8px 0')};
`;

const CheckboxContainer = styled.div`
	width: 32px;
	height: 32px;
	margin-left: -7px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	-moz-user-select: none;
	-khtml-user-select: none;
	user-select: none;

	@media (max-width: 767px) {
		flex: 0 0 32px;
	}
`;

const CustomCheckbox = styled.div<{ isChecked: boolean; isInactive: boolean }>`
	width: 16px;
	height: 16px;
	border-radius: 2px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ isChecked }) =>
		isChecked &&
		css`
    &::after {
      content: url("/assets/icons/check.svg")
      position: absolute;
      width: 9.62px;
      height: 7.62px;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(40deg);
    }
  `};
`;

const CheckIcon = styled.img<{ isChecked: boolean }>`
	width: 20px;
	height: 20px;
`;

const Label = styled.div<{ isInactive: boolean }>`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: ${({ theme }) => theme.palette.grey_text};
	transition: color 0.3s ease-in;
	cursor: pointer;
	@media (max-width: 767px) {
		white-space: normal;
	}
`;

export default Checkbox;
