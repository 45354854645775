import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import useToggle from 'hooks/useToggle';
import arrow from 'images/icons/downIcon.svg';

interface ITabContainer {
	text: string;
	isTabOpen?: boolean;
	children?: React.ReactNode;
}

const TabContainer: React.FC<ITabContainer> = ({ text, isTabOpen = false, children }) => {
	const [isOpen, setIsOpen] = useToggle(isTabOpen);
	const [isOpening, setIsOpening] = useToggle(false);
	const [isClosing, setIsClosing] = useToggle(false);

	const handleClick = () => {
		if (isOpen) {
			setIsClosing(true);
			setIsOpening(false);
			setIsOpen(false);
		} else {
			setIsOpening(true);
			setIsClosing(false);
			setIsOpen(true);
		}
	};

	return (
		<Inner>
			<TitleBoxRow onClick={handleClick}>
				<Title>{text}</Title>
				<ArrowBox isOpening={isOpening} isClosing={isClosing} isOpen={isOpen}>
					<img src={arrow} alt='arrow' />
				</ArrowBox>
			</TitleBoxRow>
			<Main isOpen={isOpen}>{children}</Main>
		</Inner>
	);
};

const Inner = styled.div`
	max-width: 770px;
	flex: 0 1 770px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 24px;
	background: ${({ theme }) => theme.palette.white};
	color: ${({ theme }) => theme.palette.main};
	margin: 0 0 0 auto;
	border-bottom: 1px solid ${({ theme }) => theme.palette.bg_grey};

	@media (max-width: 550px) {
		max-width: 100vw;
		overflow: hidden;
	}
`;

const TitleBoxRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	cursor: pointer;
`;

const Main = styled.div<{ isOpen: boolean }>`
	display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
	flex-direction: column;
	flex: 1 1 auto;
	width: 100%;
`;

const Title = styled.h5`
	font-family: MuseoSans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	margin: 5px 15px 5px 0;
`;

interface IArrowBox {
	isOpening: boolean;
	isClosing: boolean;
	isOpen: boolean;
}

const ArrowBox = styled.div<IArrowBox>`
	margin-right: 5px;

	& svg,
	img {
		transition: all 0.3s ease 0s;
		transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

		${({ isOpening }) =>
			isOpening &&
			css`
				animation: ${rotateIconOpenAnimation} 0.5s;
			`}

		${({ isClosing }) =>
			isClosing &&
			css`
				animation: ${rotateIconCloseAnimation} 0.5s;
			`}
	}
`;

const rotateIconOpenAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

const rotateIconCloseAnimation = keyframes`
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

export default TabContainer;
