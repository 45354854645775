import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import useCountdown from 'hooks/useCountdown';
import useToggle from 'hooks/useToggle';
import ApiDinamicsDirectus from 'apiFolder/directus-dynamic';

import loader from 'images/spinner.svg';

import ModalHeader from 'components/booking/ModalHeader';
import ModalFooter from 'components/booking/ModalFooter';

export interface IModal {
	phone: string;
	appointId: string;
	// isOTP: boolean;
	onCloseModal: () => void;
	setConfirmedName: () => void;
	setIsSuccess: (key: boolean) => void;
}

const OTP_LENGTH = 4;
const RESEND_TIME = 120;

const OTPModal: React.FC<IModal> = ({
	onCloseModal,
	setConfirmedName,
	phone,
	appointId,
	setIsSuccess,
	// isOTP
}) => {
	const [isSending, setIsSending] = useToggle(false);
	const [isAppear, setIsAppear] = useToggle(true);
	const [otp1, setOtp1] = useState('');
	const [otp2, setOtp2] = useState('');
	const [otp3, setOtp3] = useState('');
	const [otp4, setOtp4] = useState('');
	const [isResend, setIsResend] = useToggle(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [resendTime, setResendTime] = useState(RESEND_TIME);
	const [isRunning, setIsRunnig] = useState(true);

	const countDown = useCountdown(resendTime, isRunning, setIsRunnig);

	const displayedPhone = `${phone.slice(0, 5)}${`*`.repeat(+(phone.length - 7))}${phone.slice(-2)}`;

	// useOtpScript(isOTP);

	useEffect(() => {
		setIsAppear(true);
	}, []);

	useEffect(() => {
		if ('OTPCredential' in window) {
			const ac = new AbortController();

			navigator.credentials
				.get({
					otp: { transport: ['sms'] },
					signal: ac.signal,
				} as any)
				.then((otp: any) => {
					const code = otp.code.split('');

					setOtp1(code[0]);
					setOtp2(code[1]);
					setOtp3(code[2]);
					setOtp4(code[3]);

					setIsSending(true);
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					ac.abort();
				});
		}
	}, []);

	useEffect(() => {
		if (countDown > 0) return;

		setIsRunnig(false);
	}, [countDown]);

	useEffect(() => {
		if (!isSending) return;

		const otp = `${otp1}${otp2}${otp3}${otp4}`;

		sendOTP(otp, appointId)
			.then(() => {
				setIsSuccess(true);
			})
			.catch((err) => {
				console.log(err);
				setIsSuccess(false);
			})
			.finally(() => {
				setIsSending(false);
				onCloseModal();
				return () => setConfirmedName();
			});
	}, [isSending]);

	useEffect(() => {
		if (isResend) {
			resendOTP(appointId)
				.then((_) => {
					setResendTime(RESEND_TIME);
					setIsRunnig(true);
				})
				.catch((err) => {
					setErrorMessage(err.message);
				})
				.finally(() => setIsResend(false));
		}
	}, [isResend]);

	const sendOTP = async (otp: string, id: string) => {
		const dynamicDirectusApi = ApiDinamicsDirectus.getInstance();
		return dynamicDirectusApi.confirmNewBookingWithOTPDirectus(otp, id);
	};

	const resendOTP = async (id: string) => {
		const dynamicDirectusApi = ApiDinamicsDirectus.getInstance();
		return dynamicDirectusApi.resendOTP(id);
	};

	const closeClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setConfirmedName();
			onCloseModal();
		}
	};

	const handleFocus = (e: React.ChangeEvent<HTMLElement>): void => {
		if (e.target.nextSibling) {
			const sibl = e.target.nextSibling as HTMLElement;
			sibl.focus();
		}
	};

	const inputfocus = (elmnt: any) => {
		if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
			const next = elmnt.target.tabIndex - 2;
			if (next > -1) {
				elmnt.target.form.elements[next].focus();
			}
		} else {
			const next = elmnt.target.tabIndex;

			if (next < OTP_LENGTH) {
				elmnt.target.form.elements[next].focus();
			}
		}
	};

	const handleResend = () => {
		if (isResend) return;

		setIsResend(true);
	};

	const onSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		setIsSending(true);
	};

	return (
		<Wrapper isAppear={isAppear} onClick={closeClickHandler}>
			{isSending && (
				<Loader>
					<img src={loader} alt='loader' />
				</Loader>
			)}
			<FormContentWrap onSubmit={onSubmit}>
				<ModalHeader
					onCloseModal={() => {
						setConfirmedName();
						onCloseModal();
					}}
				/>
				<Main>
					<FormContainer>
						<TitleBlock>
							<Title>Verification Code</Title>
						</TitleBlock>
						<SubTitle>{`Please enter the verification code sent to ${displayedPhone}`}</SubTitle>
						<InnerForm>
							<InputWrap>
								<input
									id='otp1'
									type='text'
									name='otp1'
									autoComplete='one-time-code'
									autoFocus={true}
									onChange={(e) => {
										setOtp1(e.target.value);
										handleFocus(e);
									}}
									value={otp1}
									tabIndex={1}
									maxLength={1}
									onKeyUp={(e) => inputfocus(e)}
								/>
							</InputWrap>
							<InputWrap>
								<input
									id='otp2'
									name='otp2'
									type='text'
									autoComplete='one-time-code'
									maxLength={1}
									onChange={(e) => {
										setOtp2(e.target.value);
										handleFocus(e);
									}}
									value={otp2}
									tabIndex={2}
									onKeyUp={(e) => inputfocus(e)}
								/>
							</InputWrap>
							<InputWrap>
								<input
									id='otp3'
									name='otp3'
									type='text'
									autoComplete='one-time-code'
									maxLength={1}
									onChange={(e) => {
										setOtp3(e.target.value);
										handleFocus(e);
									}}
									value={otp3}
									tabIndex={3}
									onKeyUp={(e) => inputfocus(e)}
								/>
							</InputWrap>
							<InputWrap>
								<input
									id='otp4'
									name='otp4'
									type='text'
									autoComplete='one-time-code'
									maxLength={1}
									onChange={(e) => {
										setOtp4(e.target.value);
										handleFocus(e);
									}}
									value={otp4}
									tabIndex={4}
									onKeyUp={(e) => inputfocus(e)}
								/>
							</InputWrap>
						</InnerForm>
						{!errorMessage ? (
							isRunning ? (
								<Message>
									New code will be available in
									<Span countDown={countDown}>{countDown}</Span>
									seconds
								</Message>
							) : (
								<Resend onClick={handleResend} isResend={isResend}>
									{isResend ? 'Sending...' : 'Resend code?'}
								</Resend>
							)
						) : (
							<ErrorText>{errorMessage}</ErrorText>
						)}
					</FormContainer>
				</Main>
				<ModalFooter isOtp isActive={!!otp1 && !!otp2 && !!otp3 && !!otp4} />
			</FormContentWrap>
		</Wrapper>
	);
};

const Wrapper = styled.div<{ isAppear: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px;
	margin: 0 auto;
	background-color: ${({ theme }) => theme.palette.modal_bg};
	backdrop-filter: blur(2px);
	overflow: hidden;
	z-index: 200;

	animation-name: ${({ isAppear }) => isAppear && fadeIn};
	animation-duration: 0.4s;

	@media (max-width: 767px) {
		padding: 8px;
	}
	@media (max-width: 500px) {
		padding: 0px;
	}
`;

const FormContentWrap = styled.form`
	max-width: 700px;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.bg_grey};
	margin: 0px auto;
	border-radius: 4px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;
const Main = styled.div`
	flex: 1 1 auto;
	width: 100%;
	overflow-y: auto;

	@media (min-width: 768px) {
		scrollbar-color: ${({ theme }) => theme.palette.items_border};
		scrollbar-width: none;

		&::-webkit-scrollbar {
			transition: all 0.4s ease-in;
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
			-webkit-box-shadow: inset 0 0 0 transparent;
			box-shadow: inset 0 0 0 transparent;
			margin: 0px 0;
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.palette.items_border};
			border-radius: 0px;
			outline: 2px solid transparent;
			height: 50%;
		}

		&:hover::-webkit-scrollbar-thumb {
			background: ${({ theme }) => theme.palette.bg};
		}
		&::-webkit-scrollbar-thumb:hover {
			background: ${({ theme }) => theme.palette.bg};
		}
	}
`;

const FormContainer = styled.div`
	width: 100%;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	padding: 40px 24px;
	background-color: ${({ theme }) => theme.palette.white};
	@media (max-width: 767px) {
		padding: 25px 16px 18px;
	}
`;
const Inner = styled.div`
	max-width: 880px;
	width: 100%;
	margin: 0px auto;
	border-radius: 4px;
	overflow: hidden;
`;
const InnerForm = styled(Inner)`
	display: flex;
	justify-content: center;
	gap: 30px;
	padding: 30px 0;
`;

const InputWrap = styled.div`
	flex: 0 0 50px;
	width: 50px;
	height: 50px;

	& input {
		width: 100%;
		height: 100%;
		border: 1px solid #bdc5cd;
		border-radius: 4px;
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 16px;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		&[type='number'] {
			-moz-appearance: textfield;
		}
	}
`;

const TitleBlock = styled.div`
	@media (max-width: 767px) {
		margin: 0px 0 0 16px;
	}
`;
const Title = styled.p`
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	color: ${({ theme }) => theme.palette.main};
	text-align: center;

	@media (max-width: 767px) {
		padding: 0px;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		margin: 0px 0 20px;
	}
`;

const SubTitle = styled(Title)`
	margin: 34px 0 17px;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Loader = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 210;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background-color: ${({ theme }) => theme.palette.modal_bg};
	backdrop-filter: blur(2px);

	& > img {
		width: 200px;
		height: 200px;
		max-width: 100%;
		object-fit: contain;
	}
`;

const Resend = styled.div<{ isResend: boolean }>`
	width: 125px;
	height: 20px;
	margin-left: auto;
	color: ${({ theme, isResend }) => (isResend ? theme.palette.green : theme.palette.purp)};
	text-decoration: ${({ isResend }) => (isResend ? 'none' : 'underline')};
	padding: 0 10px 0 0;
	transition: all 0.3s ease;
	cursor: ${({ isResend }) => (isResend ? 'default' : 'pointer')};

	&:hover {
		text-decoration: none;
	}
`;

const Message = styled.p`
	padding: 0 10px 0 0;
	margin-left: auto;
`;
const ErrorText = styled.p`
	padding: 0 10px 0 0;
	margin: 0 auto;
	color: ${({ theme }) => theme.palette.fail};
`;

const Span = styled.span<{ countDown: number }>`
	display: inline-block;
	width: ${({ countDown }) => (countDown > 99 ? '25' : countDown > 9 ? '20' : '15')}px;
	text-align: center;
	margin: 0 6px;
`;

export default OTPModal;
