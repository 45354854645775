import React, { useState } from 'react';
import styled from 'styled-components';

import { ClinicCard, ISuburb } from 'templates/booking';

import pin from 'images/icons/suburb-location-pin.png';
import pinActive from 'images/icons/suburb-location-pin-blue.png';

interface IMarker {
	id: string;
	item: ClinicCard | ISuburb;
	text: string;
	isActiveMarker?: boolean;
	hadleMarkerClick: () => void;
}

const MarkerContainer: React.FC<IMarker> = ({ id, text, hadleMarkerClick, isActiveMarker = false }) => {
	const [isShowInfo, setIsShowInfo] = useState<boolean>(false);

	return (
		<Marker
			isShowInfo={isShowInfo}
			isActiveMarker={isActiveMarker}
			onClick={hadleMarkerClick}
			onMouseEnter={() => setIsShowInfo(true)}
			onMouseLeave={() => setIsShowInfo(false)}
		>
			<img id={id} src={isShowInfo || isActiveMarker ? pinActive : pin} alt='location pin' />
			{isShowInfo && (
				<InfoBox>
					<Text>{text}</Text>
				</InfoBox>
			)}
		</Marker>
	);
};

const Marker = styled.div<{ isShowInfo: boolean; isActiveMarker: boolean }>`
	position: relative;
	top: -42px;
	left: -15px;
	width: 30px;
	height: 42px;
	z-index: ${({ isShowInfo, isActiveMarker }) => (isShowInfo ? 3 : isActiveMarker ? 1 : 'none')};
	transition: all 0.2s ease;
	cursor: pointer;

	& > img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: contain;
		border-radius: 50%;
	}
`;

const InfoBox = styled.div`
	transition: all 0.3s ease;
	position: absolute;
	bottom: 105%;
	left: 50%;
	transform: translateX(-50%);
	min-width: 150px;
	max-width: 250px;
	z-index: 5;
	font-size: 14px;
	color: black;
	background-color: white;
	border-radius: 5px;
	padding: 5px;
	z-index: 7;
	box-shadow: 0 2px 7px 1px rgb(0, 0, 0, 0.3);
  cursor: auto;
  &:after{
    content: '';
      position: absolute;
      bottom: -5%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid #fff;
  }
}
`;
const Text = styled.h1``;

export default MarkerContainer;
