import React, { useEffect, useMemo, useState } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import circulIcon from 'images/icons/circleIcon.svg';
import circleBlueIcon from 'images/icons/circleBlueIcon.svg';
import { IClinic, ISuburb } from 'templates/booking';
import SkeletonMapContainer from '../skeleton/SkeletonMapContainer';

export const AUSTRALIA_CENTER = {
	lat: -28.6274942,
	lng: 134.2125589,
};

interface ILocation {
	lat: number;
	lng: number;
}

interface IMapContainer {
	clinics: IClinic[];
	suburb: ISuburb;
	activeClinic: IClinic;
	setActiveClinic: (key: IClinic | null) => void;
}

const ICON_SIZE = 16;

const MapContainerFC: React.FC<IMapContainer> = ({ clinics, suburb, activeClinic, setActiveClinic }) => {
	const [mapCenter, setMapCenter] = useState<ILocation>(suburb?.location || AUSTRALIA_CENTER);
	const [mapZoom, setMapZoom] = useState<number>(suburb ? 13 : 5);

	useEffect(() => {
		if (!suburb) {
			setMapCenter(AUSTRALIA_CENTER);
			setMapZoom(5);
			return;
		}
		if (suburb.location) {
			setMapCenter(suburb.location);
			setMapZoom(13);
		}
	}, [suburb]);

	const hadleMarkerClick = (clinic: IClinic) => {
		if (activeClinic && activeClinic.id === clinic.id) {
			setActiveClinic(null);
			return;
		}
		setActiveClinic(clinic);
	};

	const hadleMapClick = () => {
		setActiveClinic(null);
	};

	const map = useMemo(() => {
		return (
			<Map
				google={google}
				containerStyle={{
					position: 'static',
					width: '100%',
					height: '100%',
				}}
				style={{
					width: '100%',
					height: '100%',
				}}
				center={mapCenter}
				initialCenter={mapCenter}
				zoom={mapZoom}
				onClick={hadleMapClick}
			>
				{clinics.map((clinic: IClinic) => (
					<Marker
						key={clinic.id}
						title={`${clinic.name}, ${clinic.full_address}`}
						onClick={() => hadleMarkerClick(clinic)}
						position={clinic.location}
						icon={{
							anchor: new google.maps.Point(ICON_SIZE, ICON_SIZE),
							url: circulIcon,
							scaledSize: new google.maps.Size(ICON_SIZE * 2, ICON_SIZE * 2),
						}}
						zIndex={1}
					/>
				))}
				<Marker
					key='suburbCenter'
					title={`${suburb?.label || 'Australia'} center`}
					position={suburb?.location || AUSTRALIA_CENTER}
					onClick={() => setMapCenter(suburb.location || AUSTRALIA_CENTER)}
					icon={{
						anchor: new google.maps.Point(ICON_SIZE * 0.8, ICON_SIZE * 0.8),
						url: circleBlueIcon,
						scaledSize: new google.maps.Size(ICON_SIZE * 0.8 * 2, ICON_SIZE * 0.8 * 2),
					}}
				/>
			</Map>
		);
	}, [suburb?.name]);
	return map;
};

export default GoogleApiWrapper({
	apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY!,
	LoadingContainer: SkeletonMapContainer,
})(MapContainerFC as IMapContainer);
