import React, { useEffect, useState, useRef, RefObject } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';

import useToggle from 'hooks/useToggle';
import ApiDinamicsDirectus, { AppointmentData } from 'apiFolder/directus-dynamic';

import { convertToDateString } from 'helpers/calendar';

import arrow from 'images/icons/downIcon.svg';
import auIcon from 'images/icons/australia_icon.png';

import Input from 'components/common/Input';
import Checkbox from 'components/common/CheckBox';
import { IAppointmentDateData } from './Main';
import DropDown, { IVariant } from 'components/common/DropdownModal';
import { IPatientData } from './PatientDetailsModal';

const ERROR_MESSAGES = {
	firstName: 'Please enter your first name',
	lastName: 'Please enter your last name',
	mail: 'Please enter valid email',
	phone: 'Please enter valid phone number',
	birth: 'Please enter your birth date',
	gender: 'Please enter your gender',
	insurance: 'Please enter insurance availability',
};

const GENDER_OPTIONS = [
	{
		title: '',
	},
	{
		title: 'Male',
	},
	{
		title: 'Female',
	},
	{
		title: 'Other',
	},
];

const INSURANCE_OPTIONS = [
	{
		title: '',
	},
	{
		title: 'I don’t have Health Insurance',
	},
	{
		title: 'Medibank',
	},
	{
		title: 'BUPA',
	},
	{
		title: 'HCF',
	},
	{
		title: 'NIB',
	},
	{
		title: 'HBF',
	},
	{
		title: 'Australian Unity',
	},
	{
		title: 'GMHBA',
	},
	{
		title: 'Teachers Health',
	},
	{
		title: 'Defence Health',
	},
	{
		title: 'CBHS',
	},
	{
		title: 'HIF',
	},
	{
		title: 'Latrobe',
	},
	{
		title: 'Westfund',
	},
	{
		title: 'CUA Health',
	},
	{
		title: 'Health.com.au',
	},
	{
		title: 'Health Partners',
	},
	{
		title: 'TUH',
	},
	{
		title: 'Peoplecare',
	},
	{
		title: 'GU Health Corporate',
	},
	{
		title: 'HCI',
	},
	{
		title: 'Phoenix',
	},
	{
		title: 'Transport Health',
	},
	{
		title: 'Nurses and Midwives',
	},
	{
		title: 'Reserve Bank',
	},
	{
		title: 'RT Health Fund',
	},
	{
		title: 'CBHS Corporate',
	},
	{
		title: 'CDH',
	},
	{
		title: 'MDHF',
	},
	{
		title: 'MO Health',
	},
	{
		title: 'Onemedifund',
	},
	{
		title: 'QCH',
	},
	{
		title: 'St Lukes',
	},
	{
		title: 'ACA',
	},
	{
		title: "Doctors' Health",
	},
	{
		title: 'Emergency Services',
	},
	{
		title: 'Navy Health',
	},
	{
		title: 'Police Health',
	},
	{
		title: 'I don’t have Health Insurance',
	},
];

export interface IInput {
	id?: string;
	value: string;
	error: string;
	isEdited: boolean;
}

interface IFormFields {
	isSending: boolean;
	patient: IPatientData;
	data: IAppointmentDateData;
	onChangePatientData: (key: IPatientData) => void;
	onSending: (key: boolean) => void;
	onCloseModal: (key: boolean) => void;
	onConfirm: (key: string) => void;
	setOtpPhone: (key: string) => void;
	setAppointId: (key: string) => void;
	onOpenOTPModal: () => void;
	setIsSuccess: (key: boolean) => void;
}

const FormFields: React.FC<IFormFields> = ({
	isSending,
	onSending,
	onCloseModal,
	onConfirm,
	onOpenOTPModal,
	patient,
	onChangePatientData,
	data,
	setIsSuccess,
	setOtpPhone,
	setAppointId,
}) => {
	const [firstNameInput, setFirstNameInput] = useState<IInput>({
		id: 'firstName',
		value: '',
		error: '',
		isEdited: false,
	});
	const [lastNameInput, setLastNameInput] = useState<IInput>({
		id: 'lastName',
		value: '',
		error: '',
		isEdited: false,
	});
	const [mailInput, setMailInput] = useState<IInput>({
		id: 'email',
		value: '',
		error: '',
		isEdited: false,
	});
	const [phoneInput, setPhoneInput] = useState<IInput>({
		id: 'phone',
		value: '',
		error: '',
		isEdited: false,
	});
	const [birthInput, setBirthInput] = useState<IInput>({
		id: 'birth',
		value: '',
		error: '',
		isEdited: false,
	});

	const [genderOptions, setGenderOptions] = useState<IVariant[] | null>(null);
	const [genderOption, setGenderOption] = useState<IVariant | null>(null);

	const [insuranceOptions, setInsuranceOptions] = useState<IVariant[] | null>(null);
	const [insuranceOption, setInsuranceOption] = useState<IVariant | null>(null);

	const [isIAgree, setIsIAgree] = useToggle(false);
	const [isRemember, setIsRemember] = useToggle(true);
	const [isChangedIAgree, setIsChangedIsIAgree] = useState(false);

	const { executeRecaptcha } = useGoogleReCaptcha();
	const [token, setToken] = useState('');

	const firstNameInputElement = useRef<RefObject<HTMLInputElement>>();
	const lastNameInputElement = useRef<RefObject<HTMLInputElement>>();
	const emailInputElement = useRef<RefObject<HTMLInputElement>>();
	const phoneInputElement = useRef<RefObject<HTMLInputElement>>();
	const birthInputElement = useRef<RefObject<HTMLInputElement>>();
	const iAgreeCheckboxElement = useRef<RefObject<HTMLInputElement>>();

	const refs = {
		firstName: firstNameInputElement,
		lastName: lastNameInputElement,
		email: emailInputElement,
		phone: phoneInputElement,
		birth: birthInputElement,
		agree: iAgreeCheckboxElement,
	};

	useEffect(() => {
		if (!genderOptions && !genderOption) {
			setGenderOptions(GENDER_OPTIONS);

			const variant = [...GENDER_OPTIONS][0];
			setGenderOption(variant);
		}

		if (!insuranceOptions && !insuranceOption) {
			setInsuranceOptions(INSURANCE_OPTIONS);

			const variant = [...INSURANCE_OPTIONS][0];
			setInsuranceOption(variant);
		}
	}, []);

	useEffect(() => {
		if (!executeRecaptcha) {
			return;
		}

		const handleReCaptchaVerify = async () => {
			const tokenCaptcha = await executeRecaptcha('book_appointment');
			setToken(tokenCaptcha);
		};

		handleReCaptchaVerify().catch((err) => {
			console.log(err);
			setToken('');
		});
	}, [executeRecaptcha]);

	useEffect(() => {
		if (isSending) {
			blurFirstNameHandler();
			blurLastNameHandler();
			blurPhoneNumberHandler();
			blurEmailHandler();
			blurBirthHandler();

			const inputs = [firstNameInput, lastNameInput, mailInput, birthInput, phoneInput];

			if (invalidInputsAmount(inputs) || !isIAgree) {
				const invalidInput = firstInvalidInput(inputs);
				refs[invalidInput?.id || 'agree'].current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});

				setIsChangedIsIAgree(true);
				onSending(false);
				return;
			}

			const validatedPhone = validatePhoneByCode(phoneInput.value);
			const confirmationFlag = !process.env.GATSBY_DISABLE_PHONE_VERIFICATION_ON_BOOKING ? true : null;

			const appointData = {
				email: mailInput.value,
				phone: validatedPhone,
				firstName: firstNameInput.value,
				lastName: lastNameInput.value,
				dateOfBirth: convertToDateString(birthInput.value),
				gender: genderOption!.title || '',
				insurance: insuranceOption!.title || '',
				availabilityId: data.appointmentId,
				bookingPage: window.location.pathname,
				bookingLink: '',
				recaptchaToken: token,
				confirmation: confirmationFlag,
			};

			sendPatientData(data.selectedClinicId!, appointData)
				.then((result) => {
					setIsSuccess(true);

					if (!process.env.GATSBY_DISABLE_PHONE_VERIFICATION_ON_BOOKING) {
						setOtpPhone(validatedPhone);
						setAppointId(result.id);
						onOpenOTPModal();
					}
				})
				.catch((err) => {
					console.log(err);
					setIsSuccess(false);
				})
				.finally(() => {
					onConfirm(patient.firstName.value);
					onSending(false);
					onCloseModal(true);
				});
		}
	}, [isSending]);

	const sendPatientData = async (clinicId: number, patientData: AppointmentData) => {
		const dynamicDirectusApi = ApiDinamicsDirectus.getInstance();
		return dynamicDirectusApi.postAppointmentDataDirectus(clinicId, patientData);
	};

	const validatePhoneByCode = (phone: string) => {
		const matched = phone.match(/[+^\d]/g)?.join('') as string;
		if (process.env.GATSBY_DISABLE_PHONE_VERIFICATION_ON_BOOKING) {
			if (matched.startsWith('0')) {
				return '+61' + matched.substring(1);
			}
		}
		return phone.startsWith('+') ? matched : `+${matched}`;
	};

	const onGenderChoose = (variant: IVariant | null) => {
		setGenderOption(variant);

		onChangePatientData({
			...patient,
			gender: {
				value: variant!.title,
				isValid: true,
			},
		});
	};
	const onInsuranceChoose = (variant: IVariant | null) => {
		setInsuranceOption(variant);

		onChangePatientData({
			...patient,
			insurance: {
				value: variant!.title,
				isValid: true,
			},
		});
	};

	const onAgreeChoose = () => {
		setIsIAgree(!isIAgree);
		setIsChangedIsIAgree((): boolean => {
			if (isChangedIAgree) {
				setIsChangedIsIAgree(true);
				return true;
			}
			setIsChangedIsIAgree(true);
			return true;
		});

		onChangePatientData({
			...patient,
			agree: !isIAgree,
		});
	};

	const onChooseRemember = () => {
		setIsRemember(!isRemember);

		onChangePatientData({
			...patient,
			remember: !isRemember,
		});
	};

	const validateRequiredInput = (input: IInput) => input.value.length === 0;

	const validateInput = (
		input: IInput,
		errorMessage: string,
		setMethod: (inputItem: IInput) => void,
		fn: (inputItem: IInput) => boolean,
	): boolean => {
		const error = fn(input) ? errorMessage : '';
		setMethod({
			...input,
			error,
			isEdited: true,
		});
		return error === '';
	};

	const onChangeHandler = (
		htmlInputValue: string,
		formInput: IInput,
		errorMessage: string,
		setInput: (formInputItem: IInput) => void,
	) => {
		const input = {
			...formInput,
			value: htmlInputValue,
		};
		setInput(input);
		const isValid = validateInput(input, errorMessage, setInput, validateRequiredInput);

		onChangePatientData({
			...patient,
			[input.id!]: {
				value: htmlInputValue,
				isValid,
			},
		});
	};

	const onChangeHandlerDashes = (
		htmlInputValue: string,
		formInput: IInput,
		errorMessage: string,
		setInput: (formInputItem: IInput) => void,
	) => {
		const input = {
			...formInput,
			value:
				htmlInputValue.length == 2 || htmlInputValue.length == 5 ? htmlInputValue.concat('/') : htmlInputValue,
		};
		setInput(input);
		const isValid = validateInput(input, errorMessage, setInput, validateRequiredInput);

		onChangePatientData({
			...patient,
			[input.id!]: {
				value: htmlInputValue,
				isValid,
			},
		});
	};

	const isInputError = (formInput: IInput) =>
		(formInput.isEdited && formInput.value.length === 0) || formInput.error.length > 0;

	const blurFirstNameHandler = () => {
		validateInput(firstNameInput, ERROR_MESSAGES.firstName, setFirstNameInput, validateRequiredInput);
	};

	const blurLastNameHandler = () => {
		validateInput(lastNameInput, ERROR_MESSAGES.lastName, setLastNameInput, validateRequiredInput);
	};

	const blurPhoneNumberHandler = () => {
		validateInput(phoneInput, ERROR_MESSAGES.phone, setPhoneInput, (input: IInput) => {
			if (!process.env.GATSBY_DISABLE_PHONE_VERIFICATION_ON_BOOKING) {
				return false;
			}

			const rexexp = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/gm;
			return !input.value.match(rexexp); // || input.value.length < PHONE_VALID_LENGTH;
		});
	};

	const blurEmailHandler = () => {
		validateInput(mailInput, ERROR_MESSAGES.mail, setMailInput, (input: IInput) => {
			const re = new RegExp('\\S+@\\S+\\.\\S+', 'i');

			return !input.value.match(re);
		});
	};

	const blurBirthHandler = () => {
		validateInput(birthInput, ERROR_MESSAGES.birth, setBirthInput, (input: IInput) => {
			const dateRegex = /^(?:(?:(?:0[1-9]|1\d|2[0-8])\/(?:0[1-9]|1[0-2])|(?:29|30)\/(?:0[13-9]|1[0-2])|31\/(?:0[13578]|1[02]))\/[1-9]\d{3}|29\/02(?:\/[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$/;

			const date = new Date();
			const patientDate = input.value.replace(/(\d+[/])(\d+[/])/, '$2$1');
			const hundredYears = 100;

			if (+new Date(patientDate) >= +date) {
				return true;
			}
			if (+date.getFullYear() - +new Date(patientDate).getFullYear() >= hundredYears) {
				return true;
			}
			return !input.value.match(dateRegex);
		});
	};

	const invalidInputsAmount = (items: IInput[]) =>
		items.reduce((totalErrorsNumber, item) => {
			if (item.error !== '' || item.isEdited === false) {
				totalErrorsNumber++;
			}
			return totalErrorsNumber;
		}, 0);

	const firstInvalidInput = (items: IInput[]) =>
		[...items].find((item) => {
			if (item.error !== '' || item.isEdited === false) {
				return item;
			}
		});

	return (
		<Fields>
			<Input
				id='firstName'
				name='firstName'
				value={firstNameInput.value}
				inputRef={firstNameInputElement}
				isModal
				autoComplete='on'
				onChange={(e) => onChangeHandler(e, firstNameInput, ERROR_MESSAGES.firstName, setFirstNameInput)}
				beforeContent='First name*'
				fullWidth
				maxWidth='47.5%'
				isError={isInputError(firstNameInput)}
				errorText={firstNameInput.error.length > 0 ? firstNameInput.error : ERROR_MESSAGES.firstName}
				onBlur={blurFirstNameHandler}
			/>
			<Input
				id='lastName'
				name='lastName'
				value={lastNameInput.value}
				inputRef={lastNameInputElement}
				isModal
				autoComplete='on'
				onChange={(e) => onChangeHandler(e, lastNameInput, ERROR_MESSAGES.lastName, setLastNameInput)}
				beforeContent='Last name*'
				fullWidth
				maxWidth='47.5%'
				isError={isInputError(lastNameInput)}
				errorText={lastNameInput.error.length > 0 ? lastNameInput.error : ERROR_MESSAGES.lastName}
				onBlur={blurLastNameHandler}
			/>
			<Input
				id='email'
				// type='email'
				inputmode='email'
				name='email'
				value={mailInput.value}
				inputRef={emailInputElement}
				isModal
				autoComplete='on'
				onChange={(e) => onChangeHandler(e, mailInput, ERROR_MESSAGES.mail, setMailInput)}
				beforeContent='Email Address*'
				fullWidth
				maxWidth='47.5%'
				isError={isInputError(mailInput)}
				errorText={mailInput.error.length > 0 ? mailInput.error : ERROR_MESSAGES.mail}
				onBlur={blurEmailHandler}
			/>
			<Input
				id='phone'
				// type='tel'
				inputmode='tel'
				name='phone'
				value={phoneInput.value}
				inputRef={phoneInputElement}
				isModal
				autoComplete='on'
				onChange={(e) => onChangeHandler(e, phoneInput, ERROR_MESSAGES.phone, setPhoneInput)}
				hintText='Enter full mobile number starting with the country code [ex. +61] without any ( ) or dashes'
				beforeContent='Mobile number*'
				iconSrc={auIcon}
				fullWidth
				maxWidth='47.5%'
				isError={isInputError(phoneInput)}
				errorText={phoneInput.error.length > 0 ? phoneInput.error : ERROR_MESSAGES.phone}
				onBlur={blurPhoneNumberHandler}
				isAfterIcon
				iconSize={20}
			/>
			<Input
				id='birth'
				name='birth'
				// type='text'
				inputmode='decimal'
				// pattern='[0-9]*'
				value={birthInput.value}
				inputRef={birthInputElement}
				isModal
				autoComplete='on'
				onChange={(e) => onChangeHandlerDashes(e, birthInput, ERROR_MESSAGES.birth, setBirthInput)}
				beforeContent='DD/MM/YYYY*'
				fullWidth
				maxWidth='47.5%'
				isError={isInputError(birthInput)}
				errorText={birthInput.error.length > 0 ? birthInput.error : ERROR_MESSAGES.birth}
				onBlur={blurBirthHandler}
				maxLength={10}
			/>
			<DropdownWrap>
				<DropDown
					id='gender'
					variants={genderOptions}
					placeholder='Gender'
					chosenOption={genderOption}
					icon={arrow}
					onChoose={onGenderChoose}
					isForm
				/>
			</DropdownWrap>
			<ControlsBlock>
				<DropdownWrapLast>
					<DropDown
						id='insurance'
						variants={insuranceOptions}
						placeholder='Select your Health Insurance provider'
						chosenOption={insuranceOption}
						icon={arrow}
						onChoose={onInsuranceChoose}
						isForm
						height={205}
					/>
				</DropdownWrapLast>
				<CheckboxControls>
					<Checkbox
						id='agree'
						onCheck={onAgreeChoose}
						isChangedIAgree={isChangedIAgree}
						checkboxRef={iAgreeCheckboxElement}
					>
						<CheckboxText>
							<>
								<Text isIAgree={isIAgree} isChangedIAgree={isChangedIAgree}>
									I accept
								</Text>
								<HightlightedText>terms of use</HightlightedText>
								<Text isIAgree={isIAgree} isChangedIAgree={isChangedIAgree}>
									and
								</Text>
								<HightlightedText>privacy policy</HightlightedText>
							</>
						</CheckboxText>
					</Checkbox>
					<Checkbox id='remember' onCheck={onChooseRemember} isChecked>
						Remember my details for next time
					</Checkbox>
				</CheckboxControls>
			</ControlsBlock>
		</Fields>
	);
};

const Fields = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;

	& > div {
		padding: 15px 0 0 0;
	}
`;

const DropdownWrap = styled.div`
	position: relative;
	width: 100%;
	max-width: 47.5%;
	margin: 0 0 20px 0;
	font-size: 16px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	-webkit-letter-spacing: normal;
	-moz-letter-spacing: normal;
	-ms-letter-spacing: normal;
	letter-spacing: normal;
	color: #000000;
	outline: none;
	-webkit-transition: color 0.45s ease-in;
	transition: color 0.45s ease-in;
	z-index: 17;

	@media (max-width: 767px) {
		max-width: 100%;
	}
`;
const DropdownWrapLast = styled(DropdownWrap)`
	max-width: 100%;
	z-index: 15;
`;

const ControlsBlock = styled.div`
	width: 100%;
	margin: 0 0 8px 0;
	white-space: nowrap;
`;

const CheckboxControls = styled.div`
	width: 100%;
	margin: 30px 0 0 0;
	white-space: nowrap;
`;

const CheckboxText = styled.span`
	margin: 0 4px;
`;

const Text = styled.span<{ isIAgree: boolean; isChangedIAgree: boolean }>`
	color: ${({ theme, isIAgree, isChangedIAgree }) => !isIAgree && isChangedIAgree && theme.palette.fail};
`;
const HightlightedText = styled.span`
	margin: 0 5px;
	color: ${({ theme }) => theme.palette.light};
`;

export default FormFields;
