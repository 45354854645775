import React from 'react';
import styled from 'styled-components';

import { IAppointmentDate } from './Main';

import Appointments from 'components/booking/Appointments';

interface IBookingHeaderAppoints {
	isOnTop: boolean;
	isLoading: boolean;
	appointment: IAppointmentDate;
	appointments: IAppointmentDate[];
	onDateCellClick: (key: IAppointmentDate) => void;
	setIsOnTop: (key: boolean) => void;
}

const BookingHeaderApoints: React.FC<IBookingHeaderAppoints> = ({
	appointments,
	appointment,
	isOnTop,
	onDateCellClick,
	setIsOnTop,
	isLoading,
}) => {
	return (
		<Wrapper isOnTop={isOnTop}>
			<InnerBottom>
				<Appointments
					appointment={appointment}
					appointments={appointments}
					onDateCellClick={onDateCellClick}
					setIsOnTop={setIsOnTop}
					isLoading={isLoading}
				/>
			</InnerBottom>
		</Wrapper>
	);
};

const Wrapper = styled.div<{ isOnTop: boolean }>`
	position: fixed;
	top: 79px;
	left: 0;
	width: 100%;
	border-bottom: 1px solid ${({ theme }) => theme.palette.inactive};
	background-color: ${({ theme }) => theme.palette.white};
	z-index: ${({ isOnTop }) => (isOnTop ? 110 : 100)};

	@media (max-width: 767px) {
		top: 122px;
	}
`;

const InnerTop = styled.div`
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 26px 24px;
	margin: 0 auto;

	@media (max-width: 1280px) {
		overflow-x: auto;
	}
`;

const InnerBottom = styled(InnerTop)`
	padding: 16px 24px;

	@media (max-width: 767px) {
		padding: 12px 16px;
	}
`;

export default BookingHeaderApoints;
