import { IAppointmentDate } from 'components/booking/Main';
import { getDatesBetweenDates, getDateStringYMD } from './calendar';

export const PERIOD_IN_DAYS = 7;
export const WORK_DAY_END_HOURS = 17;

const today = new Date();
const tomorrow = new Date(new Date(today).setDate(new Date(today).getDate() + 1));
const seventhdayFromNow = new Date(new Date(today).setDate(new Date(today).getDate() + PERIOD_IN_DAYS));
const sevendays = getDatesBetweenDates(today, seventhdayFromNow);

const todayStirng = getDateStringYMD(today);
const tomorrowStirng = getDateStringYMD(tomorrow);
const todayHours = today.getHours();

export const appointsmentWeek = sevendays.map((day) => {
	const newDayString = getDateStringYMD(day);

	if (newDayString === todayStirng && +todayHours < WORK_DAY_END_HOURS) {
		return {
			date: 'today',
			timeString: newDayString,
			isActive: true,
		};
	}
	if (newDayString === todayStirng && +todayHours >= WORK_DAY_END_HOURS) {
		return {
			date: 'today',
			timeString: newDayString,
			isActive: false,
		};
	}
	if (newDayString === tomorrowStirng && +todayHours < WORK_DAY_END_HOURS) {
		return {
			date: 'tomorrow',
			timeString: newDayString,
			isActive: false,
		};
	}
	if (newDayString === tomorrowStirng && +todayHours >= WORK_DAY_END_HOURS) {
		return {
			date: 'tomorrow',
			timeString: newDayString,
			isActive: true,
		};
	}
	return {
		date: `${new Date(newDayString!).toLocaleString('en-Au', { weekday: 'short', day: 'numeric' })}`,
		timeString: newDayString,
		isActive: false,
	};
}) as IAppointmentDate[];

export default appointsmentWeek;
