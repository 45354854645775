import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonContainer: React.FC<{ numberOfCards: string[] }> = ({ numberOfCards }) => {
	return (
		<ClinicsClinicsList>
			{numberOfCards.map((num) => (
				<CardContainer key={`${num}hide`}>
					<CardInfoBlock>
						<CardInfo>
							<CardPopularBox>
								<CardPopular>
									<Skeleton width={120} />
								</CardPopular>
								{/* <CardPopularIcon>
									<Skeleton height={22} width={22} borderRadius={100} />
								</CardPopularIcon> */}
							</CardPopularBox>
							<AddressMob>
								<Skeleton width={350} height={22} />
							</AddressMob>
							<CardTitle>
								<Skeleton width={250} height={22} />
							</CardTitle>
							<CardAddressBlock>
								<CardAddressIcon>
									<Skeleton borderRadius={50} height={14} width={15} />
								</CardAddressIcon>
								<AddressInfo>
									<Address>
										<Skeleton width={350} height={14} />
									</Address>
									<AddressMob>
										<Skeleton width={30} height={14} />
									</AddressMob>
									<Distance>
										<Skeleton width={300} height={14} />
									</Distance>
								</AddressInfo>
							</CardAddressBlock>
						</CardInfo>
						<CardInfoLogo>
							<Skeleton />
						</CardInfoLogo>
					</CardInfoBlock>
					<CardTimeCells>
						<Skeleton width={670} />
					</CardTimeCells>
					<CardQuestionBlock>
						<CardSpan>
							<Skeleton width={450} />
						</CardSpan>
						<CardCloseIcon>
							<Skeleton width={20} height={20} borderRadius={50} />
						</CardCloseIcon>
					</CardQuestionBlock>
				</CardContainer>
			))}
		</ClinicsClinicsList>
	);
};

const ClinicsClinicsList = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 20px;

	@media (max-width: 1023px) {
		align-items: center;
	}
	@media (max-width: 767px) {
		padding-top: 0px;
	}
`;

const CardContainer = styled.div`
	flex: 1 1 auto;
	width: 100%;
	padding: 24px;
	background-color: ${({ theme }) => theme.palette.white};
	box-shadow: 0px 4px 14px ${({ theme }) => theme.palette.shadow};
	margin-bottom: 70px;
	border-radius: 12px;

	@media (max-width: 1280px) {
		margin-bottom: 36px;
	}

	@media (max-width: 1023px) {
		margin-bottom: 36px;
		padding: 24px 16px 36px;
		max-width: 382px;
	}
	@media (max-width: 413px) {
		& > span > span {
			max-width: 50px;
		}
	}
	@media (max-width: 413px) {
		max-width: calc(100vw - 32px);
	}
`;

const CardPopularBox = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 6px;
`;
const CardPopular = styled.div`
	padding: 7px 13px;
	color: ${({ theme }) => theme.palette.purp};
	background-color: ${({ theme }) => theme.palette.bg_purp};
	margin-right: 12px;

	@media (max-width: 1225px) {
		padding: 5px 10px;
	}
	@media (max-width: 767px) {
		& > span > span {
			width: 70px;
			max-width: 70px;
		}
	}
`;

const CardInfoBlock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-bottom: 11px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.bg_grey};

	@media (max-width: 1225px) {
		align-items: flex-start;
	}
`;

const CardInfo = styled.div`
	margin-right: 16px;
`;

const CardTitle = styled.div`
	color: ${({ theme }) => theme.palette.main};
	margin-bottom: 10px;

	@media (max-width: 1225px) {
		& > span > span {
			max-width: 100px;
		}
	}
	@media (max-width: 413px) {
		& > span > span {
			max-width: 50px;
		}
	}
`;
const CardAddressBlock = styled.div`
	flex: 1 1 auto;
	display: flex;
	color: ${({ theme }) => theme.palette.grey_text};

	@media (max-width: 1225px) {
		& > span > span {
			max-width: 150px;
		}
	}
	@media (max-width: 413px) {
		& > span > span {
			max-width: 80px;
		}
	}
`;
const CardAddressIcon = styled.div`
	margin-right: 10px;
	position: relative;
	width: 17px;
	height: 17px;
`;
const AddressInfo = styled.div`
	margin-top: 10px;
`;
const Address = styled.div`
	display: flex;
	@media (max-width: 1225px) {
		& > span > span {
			max-width: 200px;
		}
	}
	@media (max-width: 450px) {
		& > span > span {
			max-width: 120px;
		}
	}
`;

const AddressMob = styled(Address)`
	@media (min-width: 1226px) {
		display: none;
	}
`;
const Distance = styled.div`
	flex: 1 1 100%;
	width: 100%;
	display: flex;
	font-weight: 300;
	font-size: 14px;
	line-height: 24px;
	@media (max-width: 1225px) {
		& > span > span {
			max-width: 150px;
		}
	}
	@media (max-width: 450px) {
		& > span > span {
			max-width: 100px;
		}
	}
`;
const CardInfoLogo = styled.div`
	flex: 0 0 180px;
	width: 180px;
	height: 108px;
	border-radius: 4px;
	margin: 0 0 10px 0;
	overflow: hidden;

	& span {
		width: 100%;
		height: 108px;
	}

	@media (max-width: 1225px) {
		flex: 0 0 100px;
		width: 100px;
		height: 60px;
	}
`;
const CardQuestionBlock = styled.div`
	display: flex;
	justify-content: space-between;
	border: 1px solid #c1c0c0;
	background: #f8f8f8;
	box-sizing: border-box;
	border-radius: 12px;
	padding: 12px 42px 12.5px 24px;

	@media (max-width: 1225px) {
		padding: 12px 42px 12.5px 24px;
	}
`;
const CardSpan = styled.div`
	height: 100%;
	margin-right: 16px;
	overflow: hidden;

	@media (max-width: 1023px) {
		& > span > span {
			max-width: 280px;
		}
	}
	@media (max-width: 413px) {
		& > span > span {
			max-width: 285px;
		}
	}
`;
const CardCloseIcon = styled.div``;

const CardTimeCells = styled.div`
	height: 196px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	border-radius: 12px;
	margin: 16px 0px 26px;
	overflow: hidden;
	& span {
		height: 196px;
	}

	@media (max-width: 1225px) {
		height: 343px;
		& > span {
			height: 343px;
		}
		& > span > span {
			max-width: 460px;
			height: 343px;
		}
	}

	@media (max-width: 413px) {
		& > span > span {
			max-width: calc(100vw - 62px);
		}
	}
`;

export default SkeletonContainer;
