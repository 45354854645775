import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { IAppointmentDate, IAppointmentDateData } from './Main';
import Message from 'components/booking/Message';
import Data from 'components/booking/Data';

interface IConfirm {
	data: IAppointmentDateData;
	appoint: IAppointmentDate;
	confirmedName: string;
	isSuccess: boolean;
	onCloseModal: (key: boolean) => void;
}

const ConfirmContainerModal: React.FC<IConfirm> = ({ confirmedName, data, appoint, onCloseModal, isSuccess }) => {
	const closeClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			onCloseModal(true);
		}
	};

	return (
		<Link to='/'>
			<Wrapper onClick={closeClickHandler}>
				<Main isSuccess={isSuccess}>
					<Message isSuccess={isSuccess} confirmedName={confirmedName} />
					{isSuccess && <Data data={data} appoint={appoint} />}
				</Main>
			</Wrapper>
		</Link>
	);
};

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px;
	margin: 0 auto;
	background-color: ${({ theme }) => theme.palette.modal_bg};
	backdrop-filter: blur(2px);
	overflow: hidden;
	z-index: 200;

	@media (max-width: 767px) {
		padding: 8px;
	}
	@media (max-width: 500px) {
		padding: 0px;
	}
`;
const Main = styled.div<{ isSuccess: boolean }>`
	max-width: ${({ isSuccess }) => (isSuccess ? '1000px' : '880px')};
	width: 100%;
	background-color: ${({ theme }) => theme.palette.white};
	margin: 0px auto;
	border-radius: 14px;
	border: 2px solid #d5d5d5;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

export default ConfirmContainerModal;
