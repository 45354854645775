import React from 'react';
import styled, { css } from 'styled-components';

import { IOption } from './HeaderTop';
import { ClinicCard } from 'templates/booking';
import { IAppointmentDateData } from './Main';

import Card from 'components/booking/Card';

interface ICLinicsContainer {
	isShow: boolean;
	clinics: ClinicCard[];
	activeClinic: ClinicCard;
	suburb: IOption;
	data: IAppointmentDateData | null;
	onTimeCellClick: (infoData: IAppointmentDateData) => void;
	setActiveClinic: (clinic: ClinicCard) => void;
	setHoveredClinicCard: (clinic: ClinicCard | null) => void;
}

const ClinicsContainer: React.FC<ICLinicsContainer> = ({
	clinics,
	onTimeCellClick,
	data,
	suburb,
	activeClinic,
	isShow,
	setActiveClinic,
	setHoveredClinicCard,
}) => {
	return (
		<ClinicsList isShow={isShow}>
			{clinics && suburb
				? clinics.map((clinicData) => (
						<Card
							key={clinicData.id}
							clinicData={clinicData}
							onTimeCellClick={onTimeCellClick}
							data={data}
							suburb={suburb}
							activeClinic={activeClinic}
							setActiveClinic={setActiveClinic}
							setHoveredClinicCard={setHoveredClinicCard}
						/>
				  ))
				: null}
		</ClinicsList>
	);
};

const ClinicsList = styled.div<{ isShow: boolean }>`
	${({ isShow }) =>
		!isShow &&
		css`
			position: absolute;
			display: none;
			width: 0;
			height: 0;
		`}
	${({ isShow }) =>
		isShow &&
		css`
			display: flex;
			flex-direction: column;
			padding-top: 20px;

			@media (max-width: 1023px) {
				align-items: center;
			}
			@media (max-width: 767px) {
				padding-top: 0px;
			}
		`}
`;

export default ClinicsContainer;
