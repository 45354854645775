import React from 'react';

const CalendarArrowIcon = ({}: React.SVGProps<SVGSVGElement>) => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10.5429 0.542847L11.9571 1.95706L6.1642 7.74995L11.9571 13.5428L10.5429 14.9571L3.33577 7.74995L10.5429 0.542847Z'
		/>
	</svg>
);

export default CalendarArrowIcon;
