import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import axios, { CancelTokenSource } from 'axios';
import { GeolocatedProps } from 'react-geolocated';
import styled from 'styled-components';

import getSuburbsClinics from 'helpers/haversine';
import appointsmentWeek from 'helpers/appointments';
import { ClinicCard, Context } from 'templates/booking';
import { IOption } from './HeaderTop';
import { SuburbItem } from 'apiFolder/main';
import ApiAvailableClinicsDirectus, { ClinicsAvailabilityDataExpires } from 'apiFolder/directus-dynamic';
import { SuburbData, LatLng } from 'apiFolder/directus-main';

import BookingHeader from 'components/booking/BookingHeader';
import ClinicsMain from 'components/booking/ClinicsMain';
import GoogleMap from 'components/booking/map/GoogleMapReact';
import MapWrap from 'components/booking/map/MapWrap';
import PatientDetailsModal from 'components/booking/PatientDetailsModal';
import ConformContainerModal from 'components/booking/ConfirmContainerModal';
import SkeletonMapComponent from 'components/booking/skeleton/SkeletonMapContainer';
import OTPModal from 'components/booking/OTP';

interface IGeoloc {
	label: string;
}

export interface IAvailDate {
	id: number;
	day: string;
	durationInMinutes: number;
	isAvailable: false;
	startHours: number;
	startMinutes: number;
}
export interface IAvailClinic {
	[key: string]: IAvailDate[];
}
export interface IAvailability {
	available: IAvailClinic;
	noData: number[];
}

export interface IAppointmentDateData {
	address: string;
	appointmentId: string;
	location: LatLng;
	selectedClinicId?: number;
	selectedTime: string;
	title: string;
}

export interface IAppointmentDate {
	date: string;
	timeString: string;
	isActive: boolean;
}

const SEARCH_RADIUS = 25;
const TODAY_HOURS = new Date().getHours();
const WORK_DAY_END_HOURS = 17;
export const USER_LOCATION_TEXT = 'Current location';

const MainContainer: React.FC<Context & IGeoloc & GeolocatedProps> = ({ pageContext }) => {
	const contextSuburb: IOption | SuburbItem | null = !pageContext.item
		? null
		: {
				id: +pageContext.item.postcode,
				state: pageContext.item.state.text,
				location: pageContext.item.location,
				slug: pageContext.item.slug,
				name: pageContext.item.name,
				label: `${pageContext.item.name} ${pageContext.item.state.text}, ${pageContext.item.postcode}`,
		  };

	const [item, setItem] = useState<SuburbData | null>(pageContext.item);

	const apiClinics: ClinicCard[] | null = pageContext.clinicsBookNow;

	const [clinics, setClinics] = useState<ClinicCard[] | null>(apiClinics);

	const [isShowModal, setIsShowModal] = useState(false);
	const [confirmedName, setConfirmedName] = useState<string>('');
	const [data, setData] = useState<IAppointmentDateData | null>(null);
	const [appointments] = useState<IAppointmentDate[]>(appointsmentWeek);
	const [appoint, setAppoint] = useState<IAppointmentDate>(
		TODAY_HOURS < WORK_DAY_END_HOURS ? appointsmentWeek[0] : appointsmentWeek[1],
	);
	const [suburb, setSuburb] = useState<IOption | null>(contextSuburb);
	const [isZeroResults] = useState(false);
	const [isSuburbSelected, setIsSuburbSelected] = useState(false);
	const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isMapLoading, setIsMapLoading] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [activeClinic, setActiveClinic] = useState<ClinicCard | null>(null);
	const [hoveredClinicCard, setHoveredClinicCard] = useState<ClinicCard | null>(null);
	const [slug, setSlug] = useState(suburb?.slug || suburb?.urlSlug || null);
	const source = axios.CancelToken.source();
	const [isOTP, setIsOTP] = useState(false);
	const [otpPhone, setOtpPhone] = useState<string>('');
	const [appointId, setAppointId] = useState<string>('');

	useEffect(() => {
		const recap = document.querySelector('.grecaptcha-badge') as HTMLElement;
		if (recap) recap.style.display = 'none';
	}, []);

	useEffect(() => {
		if (!activeClinic) {
			window.scrollTo(0, 0);
		}
	}, [appoint.date]);

	useEffect(() => {
		window.scrollTo(0, 0);
		setActiveClinic(null);
	}, [suburb]);

	useEffect(() => {
		if (suburb && item && suburb.name !== item?.name) {
			// eliminate to allow none static page render
			setItem(null);
			// setClinics(null);

			setIsMapLoading(true);
		}

		getNearestClinics(source);

		return () => source.cancel();
	}, [suburb, appoint.date]);

	useEffect(() => {
		if (suburb === null) {
			setSlug('/book-now/');
		}

		if (suburb?.urlSlug) {
			const staticUrl = pageContext.dataSuburbSlugs.find(
				(path: string) => path === (suburb.slug || suburb.urlSlug),
			);

			if (staticUrl) {
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				navigate(staticUrl);
			}

			// solve memory leak
			return () => {
				onCloseModal();
			};
		}
	}, [suburb?.urlSlug]);

	const onDateCellClick = (clickedDate: IAppointmentDate) => {
		setAppoint(clickedDate);
	};

	const onTimeCellClick = (infoData: IAppointmentDateData) => {
		setData(infoData);
		setIsShowModal(true);
	};

	const onCloseModal = () => {
		setIsShowModal(false);
	};

	const mergeArrays = (arr1: ClinicCard[], arr2: ClinicsAvailabilityDataExpires) => {
		return arr1.map((obj: ClinicCard) => {
			if ((Object.keys(arr2).length === 0 && arr2.constructor === Object) || arr2[obj.id] === undefined) {
				return { ...obj, available: null };
			}
			return {
				...obj,
				available: arr2[obj.id].data ? [...arr2[obj.id].data] : null,
			};
		});
	};

	const fetchClinicsAvailability = async (ids: number[], items: ClinicCard[], cancelToken: CancelTokenSource) => {
		const availClinicsApiDirectus = ApiAvailableClinicsDirectus.getInstance();
		const date = appoint.timeString;

		const clinicsAvailabilityV2 = await availClinicsApiDirectus.getAvailibalityDirectusV2(
			date,
			ids.join(','),
			cancelToken,
		);

		const { available } = clinicsAvailabilityV2;

		const clinicsWithAvailability = mergeArrays(items, available) as ClinicCard[];

		const availClinics = clinicsWithAvailability.filter((cl) =>
			cl.available ? cl.available.find((el) => el.isAvailable) : !!cl.available,
		);

		if (availClinics.length === 0) {
			setIsMapLoading(false);
		}

		setClinics(availClinics);
		return true;
	};

	const getNearestClinics = (cancelToken: CancelTokenSource) => {
		if (!suburb) {
			setClinics(pageContext.clinicsBookNow);

			setIsSuburbSelected(false); // clean clinics list on redirecting to book-now page

			setIsLoading(false); // adds additional ZeroResult appearance. it was transfered to Autocomplete

			return;
		}

		const { location } = suburb;

		if (!location) {
			setIsSuburbSelected(false);
			setIsLoading(false);

			return;
		}

		setIsLoading(true);

		const nearestClinics = getSuburbsClinics(location, apiClinics, SEARCH_RADIUS);

		if (!nearestClinics?.length) {
			setClinics(null);
			setIsLoading(false);

			setIsMapLoading(false);
			setIsSuburbSelected(true);

			return;
		}
		const sortedByDistance = nearestClinics.slice().sort((a, b) => a.distance - b.distance);

		const idsList = nearestClinics.reduce((ids, clinic) => [...ids, clinic.id], [] as number[]);

		fetchClinicsAvailability(idsList, sortedByDistance, cancelToken)
			.then((response) => {
				return response;
			})
			.catch((err) => {
				setClinics(sortedByDistance);
				if (axios.isCancel(err)) return;
				console.log(err);
			})
			.finally(() => {
				setIsLoading(false);

				setIsMapLoading(false);
			});

		setIsSuburbSelected(true);
	};

	const toggleBurgerMenu = () => {
		setIsBurgerOpen((prev) => !prev);
	};

	return (
		<>
			<BookingHeader
				appointments={appointments}
				appointment={appoint}
				onDateCellClick={onDateCellClick}
				suburb={suburb!}
				setSuburb={setSuburb}
				isBurgerOpen={isBurgerOpen}
				toggleBurgerMenu={toggleBurgerMenu}
				setIsLoading={setIsLoading}
				setIsMapLoading={setIsMapLoading}
				isLoading={isLoading}
				slug={slug}
			/>
			<Main isShowModal={isShowModal} confirmedName={confirmedName} isBurgerOpen={isBurgerOpen}>
				<ClinicsMain
					isLoaded={!isLoading}
					clinics={clinics!}
					onTimeCellClick={onTimeCellClick}
					data={data}
					isZeroResults={isZeroResults}
					isSuburbSelected={isSuburbSelected}
					suburb={suburb!}
					item={item!}
					activeClinic={activeClinic}
					setActiveClinic={setActiveClinic}
					setHoveredClinicCard={setHoveredClinicCard}
					pageInfo={pageContext?.pageInformation}
				/>
				{isMapLoading ? (
					<SkeletonMapComponent />
				) : (
					<MapWrap isZeroResults={isZeroResults}>
						<GoogleMap
							clinics={clinics}
							suburb={suburb}
							activeClinic={activeClinic}
							setActiveClinic={setActiveClinic}
							hoveredClinicCard={hoveredClinicCard}
						/>
					</MapWrap>
				)}
			</Main>
			{isShowModal && data && (
				<PatientDetailsModal
					onCloseModal={onCloseModal}
					data={data}
					appoint={appoint}
					onConfirm={setConfirmedName}
					onOpenOTPModal={() => setIsOTP(true)}
					setIsSuccess={setIsSuccess}
					setOtpPhone={setOtpPhone}
					setAppointId={setAppointId}
				/>
			)}
			{isOTP && (
				<OTPModal
					onCloseModal={() => setIsOTP(false)}
					phone={otpPhone}
					appointId={appointId}
					setIsSuccess={setIsSuccess}
					setConfirmedName={() => setConfirmedName('')}
					// isOTP={isOTP}
				/>
			)}
			{confirmedName && !isOTP && (
				<ConformContainerModal
					confirmedName={confirmedName}
					appoint={appoint}
					data={data!}
					onCloseModal={onCloseModal}
					isSuccess={isSuccess}
				/>
			)}
		</>
	);
};

const Main = styled.div<{ isShowModal: boolean; confirmedName: string; isBurgerOpen: boolean }>`
	position: ${({ isShowModal, confirmedName, isBurgerOpen }) =>
		isShowModal || confirmedName || isBurgerOpen ? 'fixed' : 'static'};
	width: 100%;
	display: flex;
	padding: 150px 0 0;
	background-color: ${({ theme }) => theme.palette.bg};

	@media (max-width: 767px) {
		padding: 185px 0 0;
	}
`;

export default MainContainer;
