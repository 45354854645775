import React, { useState, useEffect, useRef, RefObject } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { ClinicCard } from 'templates/booking';
import locationPointIcon from 'images/icons/locationPointIcon.svg';
import arrowRightIcon from 'images/icons/rightArrowIcon.png';
import defaultLogoIcon from 'images/default.jpg';
import { DEFAULT_TIME_SLOTS, ITimeChip } from 'staticData/timeSlots';
import { AppointmentTime } from 'apiFolder/availability';
import { IAppointmentDateData } from './Main';
import { IOption } from './HeaderTop';
import Heading from 'components/common/Tag';

import TimeCells from 'components/booking/TimeCells';
import ImageContainerComponent from 'components/common/image/ImageContainer';

interface ICardContainer {
	clinicData: ClinicCard;
	data: IAppointmentDateData | null;
	suburb: IOption;
	activeClinic: ClinicCard;
	onTimeCellClick: (data: IAppointmentDateData) => void;
	setActiveClinic: (clinic: ClinicCard) => void;
	setHoveredClinicCard: (clinic: ClinicCard | null) => void;
}

const HOURS_12 = 12;

const CardContainer: React.FC<ICardContainer> = ({
	clinicData,
	onTimeCellClick,
	data,
	suburb,
	activeClinic,
	setActiveClinic,
	setHoveredClinicCard,
}) => {
	const [availableTimes, setAvailableTimes] = useState<ITimeChip[]>([]); // v2

	const activeRef = useRef() as RefObject<HTMLDivElement>;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { id, name, full_address, slug, location, available, distance, images_data, popular } = clinicData;

	const clinicDistance = (!isNaN(distance) && (distance / 1000).toFixed(1)) || '';

	useEffect(() => {
		if (available) {
			setAvailableTimes(timesV2(available) || DEFAULT_TIME_SLOTS);
		}
		if (!available) {
			setAvailableTimes(DEFAULT_TIME_SLOTS);
		}
	}, [available]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (activeClinic && activeClinic.id === id && activeRef.current) {
				window.scrollTo({ top: activeRef.current.offsetTop - 185, behavior: 'smooth' });
			}
		}, 100);
		return () => clearTimeout(timer);
	}, [activeClinic]);

	const timesV2 = (availabilities: AppointmentTime[]) => {
		const timeCells = availabilities.reduce((cells, availItem) => {
			const startMinutes =
				availItem.startMinutes.toString().length > 1 ? availItem.startMinutes : `${availItem.startMinutes}0`;

			const cellTime =
				availItem.startHours > HOURS_12
					? `${availItem.startHours - HOURS_12}:${startMinutes}pm`
					: availItem.startHours === HOURS_12
					? `${availItem.startHours}:${startMinutes}pm`
					: `${availItem.startHours}:${startMinutes}am`;

			const cell = {
				time: cellTime,
				appointmentId: availItem.id,
				isAvailable: availItem.isAvailable,
			} as ITimeChip;

			cells.push(cell);

			return cells;
		}, [] as ITimeChip[]);

		return timeCells;
	};

	const handleTimeCellClick = (withUpdatedData: IAppointmentDateData) => {
		onTimeCellClick({
			...withUpdatedData,
			selectedClinicId: id,
			title: name,
			address: full_address,
			location: location,
		});
	};

	return (
		<Container
			ref={activeRef}
			isActiveClinic={activeClinic && activeClinic.id === id}
			onMouseEnter={() => setHoveredClinicCard(clinicData)}
			onMouseLeave={() => setHoveredClinicCard(null)}
		>
			<InfoBlock onClick={() => setActiveClinic(clinicData)}>
				<Info>
					{popular && (
						<PopularBox>
							<Popular>POPULAR CLINIC</Popular>
						</PopularBox>
					)}
					<Heading level={3}>{name}</Heading>
					<AddressBlock>
						<AddressIcon>
							<img src={locationPointIcon} alt='location' />
						</AddressIcon>
						<AddressInfo>
							<Address>{full_address}</Address>
							{clinicDistance && (
								<Distance>{`${clinicDistance} km from ${suburb?.name ||
									suburb.value?.terms[0].value ||
									suburb?.suburbName ||
									'suburb'} centre`}</Distance>
							)}
						</AddressInfo>
					</AddressBlock>
					<ImageSeo
						src={images_data?.team_image ? `${images_data.team_image}?key=small` : ''}
						alt={images_data?.team_image_alt_text || ''}
					/>
				</Info>
				<InfoLogo>
					<ImageContainerComponent
						src={images_data?.team_image ? `${images_data.team_image}?key=medium` : defaultLogoIcon}
						alt={images_data?.team_image_alt_text || ''}
						thumb={images_data?.team_image ? `${images_data.team_image}?key=small` : defaultLogoIcon}
					/>
				</InfoLogo>
			</InfoBlock>
			<TimeCells
				times={availableTimes}
				onTimeCellClick={handleTimeCellClick}
				data={data}
				clinicId={clinicData.id}
			/>
			{slug !== null && (
				<Link to={slug}>
					<QuestionBlock>
						<Span>{`Are you ${name}?`}</Span>
						<CloseIcon>
							<img src={arrowRightIcon} width={20} height={20} alt='close' />
						</CloseIcon>
					</QuestionBlock>
				</Link>
			)}
		</Container>
	);
};

const Container = styled.div<{ isActiveClinic: boolean }>`
	flex: 1 1 auto;
	width: 100%;
	padding: 24px;
	background-color: ${({ theme }) => theme.palette.white};
	box-shadow: 0px 4px 14px
		${({ theme, isActiveClinic }) => (isActiveClinic ? theme.palette.purp : theme.palette.shadow)};
	margin-bottom: 70px;
	border-radius: 12px;
	transition: all 0.3s ease;

	// scroll-padding-top: ${({ isActiveClinic }) => isActiveClinic && '500px'};

	@media (max-width: 1280px) {
		margin-bottom: 36px;
	}
	@media (max-width: 1023px) {
		margin-bottom: 36px;
		padding: 24px 16px 36px;
		max-width: 382px;
	}
`;

const PopularBox = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
`;
const Popular = styled.div`
	font-weight: 600;
	font-size: 12px;
	letter-spacing: -0.03em;
	padding: 7px 13px;
	color: ${({ theme }) => theme.palette.purp};
	background-color: ${({ theme }) => theme.palette.bg_purp};
	text-transform: uppercase;
	margin-right: 12px;
`;

const InfoBlock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	padding-bottom: 11px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.bg_grey};

	@media (max-width: 1225px) {
		align-items: flex-start;
	}
`;

const Info = styled.div`
	margin-right: 16px;

	& h3 {
		font-family: MuseoSans !important;
		font-weight: 600 !important;
		font-size: 20px !important;
		letter-spacing: -0.03em;
		color: ${({ theme }) => theme.palette.main};
		margin-bottom: 10px;
	}
`;
const AddressBlock = styled.div`
	flex: 1 1 auto;
	display: flex;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.03em;
	color: ${({ theme }) => theme.palette.grey_text};
`;

const AddressInfo = styled.div``;
const Address = styled.div`
	display: flex;
	font-weight: 400;
	color: #000000;
`;

const Distance = styled.div`
	flex: 1 1 100%;
	width: 100%;
	display: flex;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.03em;
	color: #037934;
`;
const AddressIcon = styled.div`
	flex: 0 0 17px;
	width: 17px;
	height: 17px;
	margin-right: 10px;
	position: relative;

	& > img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: contain;
	}
`;
const InfoLogo = styled.div`
	flex: 0 0 180px;
	height: 108px;
	position: relative;
	margin: 0 0 10px 0;

	& img {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover !important;
		border-radius: 4px;
	}

	@media (max-width: 1225px) {
		flex: 0 0 100px;
		height: 60px;
		width: 100px;
	}
`;

const ImageSeo = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 0%;
	height: 0%;
	display: none;
`;
const QuestionBlock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	font-size: 16px;
	color: #868686;
	background: #f8f8f8;
	border: 1px solid #c1c0c0;
	box-sizing: border-box;
	border-radius: 12px;
	padding: 13px 42px 13px 24px;

	@media (max-width: 1225px) {
		padding: 13px 16px 13px 16px;
	}
	@media (min-width: 1225px) {
		transition: all 0.3s ease;
		&:hover {
			box-shadow: 0px 0px 10px 2px rgb(66 75 236 / 18%);
		}
	}
`;
const Span = styled.span`
	margin-right: 16px;
`;
const CloseIcon = styled.div`
	cursor: pointer;

	& > img {
		transition: all 0.3s ease;
	}

	@media (min-width: 768px) {
		&:hover {
			& > img {
				transform: scale(1.2);
			}
		}
	}
`;

export default CardContainer;
