import React from 'react';
import styled from 'styled-components';
import { ITimeChip } from 'staticData/timeSlots';
import { IAppointmentDateData } from './Main';

import TimeCellContainer from 'components/booking/TimeCell';

interface ITimeCells {
	clinicId: number;
	data: IAppointmentDateData | null;
	times?: ITimeChip[];
	onTimeCellClick: (data: IAppointmentDateData) => void;
}

const TimeCellsContainer: React.FC<ITimeCells> = ({ clinicId, times, onTimeCellClick, data }) => {
	return (
		<Container>
			{times?.map((time) => (
				<TimeCellContainer
					key={time.time}
					clinicId={clinicId}
					time={time.time}
					isAvaliable={time.isAvailable}
					onTimeCellClick={onTimeCellClick}
					data={data}
					appointmentId={time.appointmentId}
				/>
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 16px -2px 26px;
`;

export default TimeCellsContainer;
