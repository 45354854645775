import React from 'react';
import styled from 'styled-components';

import suffix from 'helpers/dateNumberSuffix';
import { IAppointmentDate, IAppointmentDateData } from './Main';
import clinicIcon from 'images/icons/homeIcon.svg';
import point from 'images/icons/locationPointIcon.svg';
import calendar from 'images/icons/calendarModalIcon.svg';
import clock from 'images/icons/clockIcon.svg';

interface IDataContainer {
	data: IAppointmentDateData;
	appoint: IAppointmentDate;
}

const DataContainer: React.FC<IDataContainer> = ({ data, appoint }) => {
	const dateWeekday = new Date(appoint?.timeString).toLocaleString('en-US', {
		weekday: 'long',
	});
	const dateDay = new Date(appoint?.timeString).toLocaleString('en-US', {
		day: 'numeric',
	});
	const dateMonth = new Date(appoint?.timeString).toLocaleString('en-US', {
		month: 'long',
	});

	const dateDaySuffix = suffix(dateDay);
	const latitude = data.location?.lat.toFixed(6);
	const longitude = data.location?.lng.toFixed(6);
	const center = `${latitude},${longitude}`;
	const key = `&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY!}`;
	const urlFirst = 'https://maps.googleapis.com/maps/api/staticmap?center=';
	const urlSecond = '&zoom=16&size=382x163&maptype=roadmap';
	const markers = `&markers=icon:https://cdn-icons-png.flaticon.com/64/684/684908.png|${center}`;

	return (
		<Main>
			<Row>
				<ListColumn>
					<Item>
						<IconBox>
							<img src={clinicIcon} alt='clinic' />
						</IconBox>
						<IconLabel>Clinic</IconLabel>
						<Text>{data?.title}</Text>
					</Item>
					<Item>
						<IconBox>
							<img src={point} alt='location point' />
						</IconBox>
						<IconLabel>Where</IconLabel>
						<Text>{data?.address}</Text>
					</Item>
					<Item>
						<IconBox>
							<img src={calendar} alt='calendar' />
						</IconBox>
						<IconLabel>When</IconLabel>
						<Text>{`${dateWeekday}, ${dateDay}${dateDaySuffix} ${dateMonth}`}</Text>
					</Item>
					<Item>
						<IconBox>
							<img src={clock} alt='time' />
						</IconBox>
						<IconLabel>Time</IconLabel>
						<TextTime>{data?.selectedTime}</TextTime>
					</Item>
				</ListColumn>
				<MapColumn>
					<MapWrap>
						<img
							src={`${urlFirst}${center}${urlSecond}${markers}${key}`}
							alt={`${data?.title} location on the map`}
						/>
					</MapWrap>
				</MapColumn>
			</Row>
		</Main>
	);
};

const Main = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	background: ${({ theme }) => theme.palette.white};
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 767px) {
		flex-direction: column;
	}
`;
const ListColumn = styled.ul`
	flex: 0 0 50%;
	padding: 24px;
	color: ${({ theme }) => theme.palette.main};

	@media (max-width: 767px) {
		flex: 0 1 100%;
		width: 100%;
		padding: 12px 18px;
	}
`;
const MapColumn = styled.div`
	flex: 0 0 50%;
	padding: 24px;
	@media (max-width: 767px) {
		flex: 0 0 100%;
		width: 100%;
		padding: 0 16px 30px;
	}
`;
const MapWrap = styled.div`
	position: relative;
	width: calc(100% - 10px);
	padding: 0 0 41.5% 0;
	border-radius: 4px;
	overflow: hidden;
	margin-left: 10px;

	& > img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 767px) {
		margin-left: 0px;
		width: 100%;
		padding: 0 0 44% 0;
	}
`;

const Item = styled.li`
	width: 100%;
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 16px;
	margin: 18px 0px;

	@media (max-width: 767px) {
		align-items: flex-start;
	}
`;
const IconLabel = styled.div`
	font-weight: 600;
	font-size: 16px;
	margin: 0 8px 0 10px;
`;

const IconBox = styled.div`
	width: 17px;
	min-width: 17px;
	height: 17px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& img {
		max-width: 100%;
	}
`;

const Text = styled.p`
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
`;

const TextTime = styled(Text)`
	text-transform: uppercase;
`;

export default DataContainer;
