import React from 'react';
import styled from 'styled-components';
import { IPost } from './PageInfoBlock';

interface IPosts {
	posts: IPost[];
}

const PostContainer: React.FC<IPosts> = ({ posts }) => {
	return (
		<PostsBlock>
			{posts.length > 0 &&
				posts.map((post, index) => (
					<Post key={post.content.slice(5, 10) + index.toString()}>{post.content}</Post>
				))}
		</PostsBlock>
	);
};

const PostsBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 35px 0 11px;

	@media (max-width: 1225px) {
		margin: 10px 0;
	}
`;

const Post = styled.div`
	flex: 0 0 47%;
	width: 47%;
	padding: 16px 16px 27px;
	margin: 15px 0;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

	@media (max-width: 1225px) {
		flex: 0 0 100%;
		width: 100%;
		padding: 18px 15px;
		margin: 10px 0;
	}
`;

export default PostContainer;
