// import { AppointmentTime } from 'apiFolder/availability';
export interface ITimeChip {
	time: string;
	appointmentId: string;
	isAvailable: boolean;
}

export interface ITimeSlot {
	appointmentId?: string;
	time: string;
	isAvailable: boolean;
	startHours: number;
	startMinutes: number;
	day?: string;
	id?: string;
	clinicDbId?: number;
	durationInMinutes?: number;
	practitionerIdFromProvider?: number | null;

	// clinicDbId?: number;
	// day?: string;
	// id?: string;
	// isAvailable: boolean;
	// startHours: number;
	// startMinutes: number;
}

export const DEFAULT_TIME_SLOTS: ITimeChip[] = [
	{
		time: '7:00am',
		isAvailable: false,
		appointmentId: '1',
	},
	{
		time: '7:30am',
		isAvailable: false,
		appointmentId: '2',
	},
	{
		time: '8:00am',
		isAvailable: false,
		appointmentId: '3',
	},
	{
		time: '8:30am',
		isAvailable: false,
		appointmentId: '4',
	},
	{
		time: '9:00am',
		isAvailable: false,
		appointmentId: '5',
	},
	{
		time: '9:30am',
		isAvailable: false,
		appointmentId: '6',
	},
	{
		time: '10:00am',
		isAvailable: false,
		appointmentId: '7',
	},
	{
		time: '10:30am',
		isAvailable: false,
		appointmentId: '8',
	},
	{
		time: '11:00am',
		isAvailable: false,
		appointmentId: '9',
	},
	{
		time: '11:30am',
		isAvailable: false,
		appointmentId: '10',
	},
	{
		time: '12:00pm',
		isAvailable: false,
		appointmentId: '11',
	},
	{
		time: '12:30pm',
		isAvailable: false,
		appointmentId: '12',
	},
	{
		time: '1:00pm',
		isAvailable: false,
		appointmentId: '13',
	},
	{
		time: '1:30pm',
		isAvailable: false,
		appointmentId: '14',
	},
	{
		time: '2:00pm',
		isAvailable: false,
		appointmentId: '15',
	},
	{
		time: '2:30pm',
		isAvailable: false,
		appointmentId: '16',
	},
	{
		time: '3:00pm',
		isAvailable: false,
		appointmentId: '17',
	},
	{
		time: '3:30pm',
		isAvailable: false,
		appointmentId: '18',
	},
	{
		time: '4:00pm',
		isAvailable: false,
		appointmentId: '19',
	},
	{
		time: '4:30pm',
		isAvailable: false,
		appointmentId: '20',
	},
	{
		time: '5:00pm',
		isAvailable: false,
		appointmentId: '21',
	},
	{
		time: '5:30pm',
		isAvailable: false,
		appointmentId: '22',
	},
	{
		time: '6:00pm',
		isAvailable: false,
		appointmentId: '23',
	},
	{
		time: '6:30pm',
		isAvailable: false,
		appointmentId: '24',
	},
	{
		time: '7:00pm',
		isAvailable: false,
		appointmentId: '25',
	},
	{
		time: '7:30pm',
		isAvailable: false,
		appointmentId: '26',
	},
	{
		time: '8:00pm',
		isAvailable: false,
		appointmentId: '27',
	},
	{
		time: '8:30pm',
		isAvailable: false,
		appointmentId: '28',
	},
];
export const TIME_SLOTS_INAVAILABLE: ITimeSlot[] = [
	{
		time: '7:00am',
		isAvailable: false,
		startHours: 7,
		startMinutes: 0,
	},
	{
		time: '7:30am',
		isAvailable: false,
		startHours: 7,
		startMinutes: 30,
	},
	{
		time: '8:00am',
		isAvailable: false,
		startHours: 8,
		startMinutes: 0,
	},
	{
		time: '8:30am',
		isAvailable: false,
		startHours: 8,
		startMinutes: 30,
	},
	{
		time: '9:00am',
		isAvailable: false,
		startHours: 9,
		startMinutes: 0,
	},
	{
		time: '9:30am',
		isAvailable: false,
		startHours: 9,
		startMinutes: 30,
	},
	{
		time: '10:00am',
		isAvailable: false,
		startHours: 10,
		startMinutes: 0,
	},
	{
		time: '10:30am',
		isAvailable: false,
		startHours: 10,
		startMinutes: 30,
	},
	{
		time: '11:00am',
		isAvailable: false,
		startHours: 11,
		startMinutes: 0,
	},
	{
		time: '11:30am',
		isAvailable: false,
		startHours: 11,
		startMinutes: 30,
	},
	{
		time: '12:00pm',
		isAvailable: false,
		startHours: 12,
		startMinutes: 0,
	},
	{
		time: '12:30pm',
		isAvailable: false,
		startHours: 12,
		startMinutes: 30,
	},
	{
		time: '1:00pm',
		isAvailable: false,
		startHours: 13,
		startMinutes: 0,
	},
	{
		time: '1:30pm',
		isAvailable: false,
		startHours: 13,
		startMinutes: 30,
	},
	{
		time: '2:00pm',
		isAvailable: false,
		startHours: 14,
		startMinutes: 0,
	},
	{
		time: '2:30pm',
		isAvailable: false,
		startHours: 14,
		startMinutes: 30,
	},
	{
		time: '3:00pm',
		isAvailable: false,
		startHours: 15,
		startMinutes: 0,
	},
	{
		time: '3:30pm',
		isAvailable: false,
		startHours: 15,
		startMinutes: 30,
	},
	{
		time: '4:00pm',
		isAvailable: false,
		startHours: 16,
		startMinutes: 0,
	},
	{
		time: '4:30pm',
		isAvailable: false,
		startHours: 16,
		startMinutes: 30,
	},
	{
		time: '5:00 pm',
		isAvailable: false,
		startHours: 17,
		startMinutes: 0,
	},
	{
		time: '5:30pm',
		isAvailable: false,
		startHours: 17,
		startMinutes: 30,
	},
	{
		time: '6:00pm',
		isAvailable: false,
		startHours: 18,
		startMinutes: 0,
	},
	{
		time: '6:30pm',
		isAvailable: false,
		startHours: 18,
		startMinutes: 30,
	},
	{
		time: '7:00pm',
		isAvailable: false,
		startHours: 19,
		startMinutes: 0,
	},
	{
		time: '7:30pm',
		isAvailable: false,
		startHours: 19,
		startMinutes: 30,
	},
	{
		time: '8:00pm',
		isAvailable: false,
		startHours: 20,
		startMinutes: 0,
	},
	{
		time: '8:30pm',
		isAvailable: false,
		startHours: 20,
		startMinutes: 30,
	},
];

const TIME_SLOTS: ITimeSlot[] = [
	{
		time: '7:00am',
		isAvailable: false,
		startHours: 7,
		startMinutes: 0,
	},
	{
		time: '7:30am',
		isAvailable: false,
		startHours: 7,
		startMinutes: 30,
	},
	{
		time: '8:00am',
		isAvailable: true,
		startHours: 8,
		startMinutes: 0,
	},
	{
		time: '8:30am',
		isAvailable: false,
		startHours: 8,
		startMinutes: 30,
	},
	{
		time: '9:00am',
		isAvailable: true,
		startHours: 9,
		startMinutes: 0,
	},
	{
		time: '9:30am',
		isAvailable: true,
		startHours: 9,
		startMinutes: 30,
	},
	{
		time: '10:00am',
		isAvailable: true,
		startHours: 10,
		startMinutes: 0,
	},
	{
		time: '10:30am',
		isAvailable: true,
		startHours: 10,
		startMinutes: 30,
	},
	{
		time: '11:00am',
		isAvailable: true,
		startHours: 11,
		startMinutes: 0,
	},
	{
		time: '11:30am',
		isAvailable: true,
		startHours: 11,
		startMinutes: 30,
	},
	{
		time: '12:00pm',
		isAvailable: false,
		startHours: 12,
		startMinutes: 0,
	},
	{
		time: '12:30pm',
		isAvailable: true,
		startHours: 12,
		startMinutes: 30,
	},
	{
		time: '1:00pm',
		isAvailable: true,
		startHours: 13,
		startMinutes: 0,
	},
	{
		time: '1:30pm',
		isAvailable: false,
		startHours: 13,
		startMinutes: 30,
	},
	{
		time: '2:00pm',
		isAvailable: true,
		startHours: 14,
		startMinutes: 0,
	},
	{
		time: '2:30pm',
		isAvailable: true,
		startHours: 14,
		startMinutes: 30,
	},
	{
		time: '3:00pm',
		isAvailable: true,
		startHours: 15,
		startMinutes: 0,
	},
	{
		time: '3:30pm',
		isAvailable: false,
		startHours: 15,
		startMinutes: 30,
	},
	{
		time: '4:00pm',
		isAvailable: true,
		startHours: 16,
		startMinutes: 0,
	},
	{
		time: '4:30pm',
		isAvailable: false,
		startHours: 16,
		startMinutes: 30,
	},
	{
		time: '5:00 pm',
		isAvailable: true,
		startHours: 17,
		startMinutes: 0,
	},
	{
		time: '5:30pm',
		isAvailable: true,
		startHours: 17,
		startMinutes: 30,
	},
	{
		time: '6:00pm',
		isAvailable: true,
		startHours: 18,
		startMinutes: 0,
	},
	{
		time: '6:30pm',
		isAvailable: false,
		startHours: 18,
		startMinutes: 30,
	},
	{
		time: '7:00pm',
		isAvailable: true,
		startHours: 19,
		startMinutes: 0,
	},
	{
		time: '7:30pm',
		isAvailable: false,
		startHours: 19,
		startMinutes: 30,
	},
	{
		time: '8:00pm',
		isAvailable: false,
		startHours: 20,
		startMinutes: 0,
	},
	{
		time: '8:30pm',
		isAvailable: false,
		startHours: 20,
		startMinutes: 30,
	},
];

export default TIME_SLOTS;
