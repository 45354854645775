import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from 'images/Logo_white.svg';

import { BsInstagram, BsLinkedin, BsTwitter } from 'react-icons/bs';
import { FaFacebookSquare } from 'react-icons/fa';
import { AiFillMediumSquare } from 'react-icons/ai';

interface IFooterBooking {
	fullWidth?: boolean;
}

const FooterBooking: React.FC<IFooterBooking> = ({ fullWidth }) => {
	return (
		<>
			<Inner fullWidth={fullWidth!}>
				<Row>
					<ColumnLeft>
						<LogoBox>
							<Link to='/'>
								<img src={Logo} alt='logo' width='187px' style={{ margin: '0px', cursor: 'pointer' }} />
							</Link>
						</LogoBox>
						<Text>
							<a href='https://bookphysio.com/' target='_blank' rel='noopener noreferrer'>
								BookPhysio.com
							</a>
							is Australia’s #1 physiotherapy booking site. We aim to help everyday Australian’s access
							reliable, evidence-based health information and suitable treatment options via our booking
							engine.
						</Text>
					</ColumnLeft>
					<ColumnRight>
						<Socials>
							<a
								href='https://www.linkedin.com/company/bookphysio'
								target='_blank'
								rel='noopener noreferrer'
							>
								<BsLinkedin
									style={{
										width: '20px',
										height: '20px',
										margin: '0 6px',
									}}
								/>
							</a>
							<a href='https://www.facebook.com/BookPhysio1/' target='_blank' rel='noopener noreferrer'>
								<FaFacebookSquare
									style={{
										width: '22px',
										height: '22px',
										margin: '0 6px',
									}}
								/>
							</a>
							<a href='https://www.instagram.com/bookphysio/' target='_blank' rel='noopener noreferrer'>
								<BsInstagram
									style={{
										width: '20px',
										height: '20px',
										margin: '0 6px',
									}}
								/>
							</a>
							<a href='https://twitter.com/bookphysio_au' target='_blank' rel='noopener noreferrer'>
								<BsTwitter
									style={{
										width: '20px',
										height: '20px',
										margin: '0 6px',
									}}
								/>
							</a>
							<a href='https://medium.com/book-physio' target='_blank' rel='noopener noreferrer'>
								<AiFillMediumSquare
									style={{
										width: '24px',
										height: '24px',
										margin: '0 6px',
									}}
								/>
							</a>
						</Socials>
						<TextMain>Questions about our product or services? </TextMain>
						<Text>Head office 2/11 York Street, Sydney NSW 2000</Text>
						<Text>Call us Monday - Friday 9am - 6pm AEST </Text>
						<Text>
							<a href='tel:+610290686658'>(02) 9068 6658</a>
						</Text>
					</ColumnRight>
				</Row>
			</Inner>
			<ListInner fullWidth={fullWidth!}>
				<ListRow>
					<Link to='/nt'>
						<Item>NT</Item>
					</Link>
					<Link to='/wa'>
						<Item>WA</Item>
					</Link>
					<Link to='/act'>
						<Item>ACT</Item>
					</Link>
					<Link to='/tas'>
						<Item>TAS</Item>
					</Link>
					<Link to='/sa'>
						<Item>SA</Item>
					</Link>
					<Link to='/qld'>
						<Item>QLD</Item>
					</Link>
					<Link to='/nsw'>
						<Item>NSW</Item>
					</Link>
					<Link to='/vic'>
						<Item>VIC</Item>
					</Link>
					<Link to='/sitemap'>
						<Item>Site Map</Item>
					</Link>
				</ListRow>
			</ListInner>
		</>
	);
};

const Inner = styled.div<{ fullWidth: boolean }>`
	max-width: ${({ fullWidth }) => (fullWidth ? '100%' : ' 770px')};
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40px 24px;
	background: ${({ theme }) => theme.palette.bg_footer};
	color: ${({ theme }) => theme.palette.white};
	margin: 0 0 0 auto;

	@media (max-width: 550px) {
		padding: 40px 17px;
	}
`;

const ListInner = styled.div<{ fullWidth: boolean }>`
	max-width: ${({ fullWidth }) => (fullWidth ? '100%' : ' 770px')};
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${({ theme }) => theme.palette.main};
	padding: 8px 24px;
	color: ${({ theme }) => theme.palette.white};
	margin: 0 0 0 auto;

	@media (max-width: 550px) {
		padding: 8px 8px 8px 34px;
	}
`;

const Row = styled.div`
	display: flex;
	max-width: 1440px;
	width: 100%;
	justify-content: space-between;
	margin: 0 auto;

	@media (max-width: 1225px) {
		flex-wrap: wrap;
	}
`;

const ListRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
	max-width: 1440px;
	margin: 0 auto;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 21px;
`;

const ColumnLeft = styled.div`
	flex: 0 0 41%;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;

	& a {
		color: ${({ theme }) => theme.palette.white};
		margin: 0 2px;
		transition: all 0.3s ease;

		&:hover {
			color: ${({ theme }) => theme.palette.purp};
		}
	}

	@media (max-width: 1225px) {
		flex: 0 0 100%;
	}
`;

const ColumnRight = styled(ColumnLeft)`
	text-align: right;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;

	@media (max-width: 1225px) {
		text-align: left;
		margin-top: 20px;
	}
`;

const LogoBox = styled.div`
	height: 45px;
	width: 100%;
	display: flex;
	align-items: center;
`;

const TextMain = styled.p`
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	margin: 5px 0;
`;

const Text = styled.p`
	margin: 5px 0;

	& > a {
		margin: 0;
		padding: 0 5px 0 0;
	}
`;

const Item = styled.li`
	color: ${({ theme }) => theme.palette.white};
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		color: ${({ theme }) => theme.palette.purp};
	}
`;

const Socials = styled(LogoBox)`
	justify-content: flex-end;

	& > a {
		display: flex;
		align-items: center;
	}

	@media (max-width: 1225px) {
		justify-content: flex-start;
	}
`;

export default FooterBooking;
