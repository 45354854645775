import React from 'react';
import styled from 'styled-components';

import noLocationImage from 'images/noLocation.png';

const ClinicsZeroResultsContainer: React.FC<{ isLoaded?: boolean }> = ({ isLoaded }) => {
	return (
		<>
			<IconBox>
				<ImgBlock>
					<img src={noLocationImage} alt='no location' width={150} height={131} />
				</ImgBlock>
			</IconBox>
			<TextBlock>
				<Text>
					No location selected
					<Split />
					<Span>Enter a suburb above to find nearby clinics.</Span>
				</Text>
			</TextBlock>
		</>
	);
};

const TextBlock = styled.div`
	width: 100%;
	display: flex;
	padding: 42px 0px 70px;
	text-align: center;
`;

const Text = styled.p`
	width: 100%;
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: -0.03em;
	color: ${({ theme }) => theme.palette.main};
`;

const Span = styled.span`
	font-weight: 400;
`;
const Split = styled.span`
	padding: 3px;
`;

const IconBox = styled.div`
	padding: 0 0 18% 0;
	display: flex;
	position: relative;

	& > img {
		position: absolute;
		top: 0;
		left: 0px;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
const ImgBlock = styled.div`
	margin: 0 auto;
`;

export default ClinicsZeroResultsContainer;
