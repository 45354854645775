import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonMapContainer: React.FC = () => {
	return (
		<MapWrap>
			<Skeleton />
		</MapWrap>
	);
};

const MapWrap = styled.div`
	position: sticky;
	top: 150px;
	width: 100%;
	height: calc(100vh - 150px);
	background-color: ${({ theme }) => theme.palette.bg_purp};

	& > span {
		display: block;
		height: calc(100% + 15px);
		position: relative;
		top: -15px;
	}
	& > span > span {
		display: block;
		height: 100%;
	}

	@media (max-width: 767px) {
		top: 185px;
		height: calc(100vh - 185px);
	}
	@media (max-width: 415px) {
		display: none;
	}
`;
export default SkeletonMapContainer;
