import React, { useEffect } from 'react';
import styled from 'styled-components';

import useToggle from 'hooks/useToggle';

interface IProps {
	date: (string | number)[];
	isActive?: boolean;
	onPick: (date: (string | number)[]) => void;
	isOtherMonth?: boolean;
	disable: boolean;
}

const DateBox: React.FC<IProps> = ({ date, isActive = false, onPick, isOtherMonth = false, disable }) => {
	const [isPicked, setIsPicked] = useToggle(isActive);

	useEffect(() => {
		setIsPicked(isActive);
	}, [isActive]);

	const pickDateClickHandler = (curDate: (string | number)[]) => {
		if (!disable) {
			onPick(curDate);
		}
	};

	const representDateNumber = (curDate: (string | number)[]) => {
		let dateNumber;
		if (curDate[1].toString()[0] === '0') {
			dateNumber = curDate[1].toString().slice(1);
		} else {
			dateNumber = curDate[1].toString();
		}
		return dateNumber;
	};

	return (
		<DateContainer
			id={date.join('-')}
			isPicked={isPicked}
			onClick={() => pickDateClickHandler(date)}
			isDisable={disable}
		>
			<DateNumber isPicked={isPicked} isOtherMonth={isOtherMonth} isDisable={disable}>
				{representDateNumber(date)}
			</DateNumber>
		</DateContainer>
	);
};

const DateContainer = styled.div<{ isPicked: boolean; isDisable: boolean }>`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ isPicked, theme }) => (isPicked ? theme.palette.purp : 'transparent')};
	border-radius: 50%;
	cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
	transition: all 0.3s ease;

	@media (min-width: 768px) {
		&:hover {
			background-color: ${({ theme, isPicked, isDisable }) =>
				isDisable ? 'none' : !isPicked && theme.palette.bg_purp};
		}
	}
	@media (max-width: 420px) {
		width: 36px;
		height: 36px;
		margin: 0 4px 18px;
	}
	@media (max-width: 330px) {
		margin: 0 4px 5px;
	}
`;

const DateNumber = styled.p<{ isPicked: boolean; isOtherMonth: boolean; isDisable: boolean }>`
	font-weight: 400;
	font-size: 14px;
	color: ${({ isPicked, isOtherMonth, isDisable, theme }) =>
		isPicked ? theme.palette.white : isOtherMonth || isDisable ? '#D8D8D8' : theme.palette.main};

	@media (min-width: 768px) {
		&:hover {
			color: ${({ theme, isPicked, isDisable }) => (isDisable ? 'none' : !isPicked && theme.palette.purp)};
		}
	}
	@media (max-width: 420px) {
		font-weight: 300;
		font-size: 18px;
		line-height: 22px;
	}
`;

export default DateBox;
