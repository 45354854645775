/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useRef, useState } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import styled from 'styled-components';

import { USER_LOCATION_TEXT } from './Main';
import { BookNowUrl } from 'helpers/encodedUrl';

import iconSrc from 'images/icons/searchIconPurp.svg';
import iconSrcInactive from 'images/icons/searchIcon.svg';
import iconClose from 'images/icons/closeIcon.svg';

import { IOption } from './HeaderTop';
import GooglePlacesAutocomplete from 'components/common/googlePlacesAutocomplete/Autocomplete';
import InputLoader from 'components/booking/InputLoader';
import Geolocation from 'components/Geolocation';

interface IAutocomplete {
	suburb: IOption;
	slug: string;
	setSuburb: (key: IOption) => void;
	setIsLoading: (key: boolean) => void;
	setIsMapLoading: (key: boolean) => void;
}

const GooglePlacesAutocompleteComponent: React.FC<IAutocomplete> = ({
	suburb,
	setSuburb,
	setIsLoading,
	setIsMapLoading,
	slug,
}) => {
	const [value, setValue] = useState<IOption | null>(suburb);
	const [isOptionsClicked, setIsOptionsClicked] = useState<boolean>(false);
	const [isLocationAccepted, setIsLocationAccepted] = useState<boolean>(false);
	const asyncRef = useRef(null);

	const customStyles = {
		container: (base: any) => ({
			...base,
			width: 396,
			minHeight: 48,
			'@media (max-width: 1280px)': {
				width: 382,
			},
			'@media (max-width: 1080px)': {
				width: '100%',
				maxWidth: 382,
			},
			'@media (max-width: 767px)': {
				maxWidth: '100%',
			},
		}),
		control: (base: any) => ({
			...base,
			display: 'flex',
			flexWrap: 'nowrap',
			height: 48,
			padding: '7px 0px 8px 12px',
			fontFamily: 'MuseoSans',
			background: '#FFFFFF',
			border: '2px solid #CDCDCD',
			borderRadius: 3,
			outline: 'none',
			fontSize: 16,
			fontWeight: 400,
			color: '#000000',
			transition: '0.3s ease-in',
			boxShadow: 'none',

			position: 'relative',
			'&:after': {
				content: "''",
				position: 'absolute',
				top: 0,
				right: 0,
				width: 14,
				height: '100%',
				background: `url(${iconSrcInactive}) no-repeat center / contain`,
				backgroundSize: '14px 14px',
				marginLeft: 10,
				marginRight: 22,
				cursor: 'pointer',
				transition: 'all 0.3s ease',
				'@media (max-width: 767px)': {
					marginRight: 17,
				},
			},

			'&:focus': {
				border: '2px solid #424BEC',
				boxShadow: '0px 0px 10px 2px rgb(66 75 236 / 18%)',
			},
			'&:hover': {
				border: '2px solid #424BEC',
				boxShadow: '0px 0px 10px 2px rgb(66 75 236 / 18%)',
			},
			'&:active': {
				border: '1px solid #CDCDCD',
				boxShadow: 'none',
			},
			'&:focus-within': {
				border: `${suburb ? '1px solid #CDCDCD' : '2px solid #424BEC'}`,
				boxShadow: `${suburb ? 'none' : '0px 0px 10px 2px rgb(66 75 236 / 18%)'}`,
			},

			'&:focus-within, &:hover, &:focus': {
				'&:after': {
					top: 0,
					right: 0,
					background: `url(${iconSrc}) no-repeat center / contain`,
				},
				color: '#424BEC',
			},
		}),
		indicatorsContainer: (base: any) => ({
			...base,
			'& svg': {
				display: 'none',
			},
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
		indicatorContainer: () => ({
			display: 'none',
		}),
		placeholder: (base: any) => ({
			...base,
			color: '#424BEC',
			fontSize: 16,
			fontWeight: 300,
		}),
		input: (base: any) => ({
			...base,
			cursor: 'text',
		}),
		valueContainer: (base: any) => ({
			...base,
			dislay: 'inline-flex',
			flex: '0 1 auto',
			paddingLeft: `${value ? '0' : '8px'}`,
		}),
		singleValue: (base: any) => ({
			...base,
			flexWrap: 'nowrap',
			backgroundColor: '#F8F8F8',
			padding: '8px 32px 8px 12px',
			borderRadius: '2px',
			minWidth: 146,
			maxWidth: 290,
			position: 'relative',
			top: 0,
			transform: 'translate(0)',
			fontSize: 14,
			fontWeight: 300,
			color: 'inherit',
			'&:after': {
				content: '""',
				position: 'absolute',
				top: 0,
				right: 10,
				width: 12,
				height: '100%',
				background: `url(${iconClose}) no-repeat center / contain`,
				cursor: 'pointer',
			},
			'@media (max-width: 413px)': {
				maxWidth: 250,
			},
			'@media (max-width: 374px)': {
				maxWidth: 210,
			},
		}),
		menu: (base: any) => ({
			...base,
			padding: '7px 10px 7px 22px',
			outline: 'none',
			fontSize: 16,
			fontWeight: 400,
		}),
		menuList: (base: any) => ({
			...base,

			'::-webkit-scrollbar': {
				width: '4px',
				height: '0px',
			},
			'::-webkit-scrollbar-track': {
				background: '#f1f1f1',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#888',
			},
			'::-webkit-scrollbar-thumb:hover': {
				background: '#555',
			},
		}),
	};

	useEffect(() => {
		// redirects from book-now to book-now
		if (window.location.search?.includes(BookNowUrl.suburb)) {
			const searchParts = window.location.search.split('?');
			const suburbValue = JSON.parse(decodeURIComponent(searchParts[1]).slice(BookNowUrl.suburb.length));

			setSuburb(suburbValue);
			setValue(suburbValue);
		}
	}, []);

	useEffect(() => {
		if (!value || !value.label) {
			setIsLoading(false); // to prevent load from main to booking page with empty value
			return;
		}

		setSuburb(value);

		if ((slug !== value.urlSlug && slug !== value.slug) || slug.includes('/book-now')) {
			// set url when redirect from main page
			history.replaceState(
				null,
				'',
				`/book-now${`?${value.label.toLowerCase().replaceAll(' ', '-').replaceAll(',', '')}` || '/'}`,
			);

			setIsMapLoading(true);
		}
	}, [value, value?.label]);

	const setEmptyValue = () => {
		setValue(null);
	};

	const userLocation = (userCoord: { latitude: number; longitude: number }, userTimestamp: number) => {
		setValue({
			id: userTimestamp,
			name: USER_LOCATION_TEXT,
			label: USER_LOCATION_TEXT,
			location: {
				lat: userCoord.latitude,
				lng: userCoord.longitude,
			},
			state: '',
		});
	};

	const blurAsync = () => {
		asyncRef.current?.blurInput();
	};

	return (
		<div>
			<GooglePlacesAutocomplete
				apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
				selectProps={{
					ref: asyncRef,
					value: value,
					onChange: setValue,
					onMenuOpen: setEmptyValue,
					placeholder: 'Search by Suburb',
					styles: customStyles,
					openMenuOnFocus: false,
					autoFocus: !value,
					isClearable: true,
					noOptionsMessage: () =>
						isOptionsClicked || isLocationAccepted ? (
							<Geolocation
								label={USER_LOCATION_TEXT}
								userLocation={userLocation}
								blurAsync={blurAsync}
								setIsLocationAccepted={setIsLocationAccepted}
							/>
						) : (
							<TextDefault onClick={() => setIsOptionsClicked(true)}>
								Permit to use current location
							</TextDefault>
						),
				}}
				autocompletionRequest={{
					bounds: [
						{ lat: -8.767933, lng: 111.793429 },
						{ lat: -43.63672, lng: 154.829255 },
					],
					types: ['(regions)'],
					componentRestrictions: {
						country: ['au'],
					},
				}}
				onLoadFailed={(error) => console.error('Could not inject Google script', error)}
			/>
		</div>
	);
};

const TextDefault = styled.p`
	color: inherit;
	&:hover {
		cursor: pointer;
	}
`;

export default GoogleApiWrapper({
	apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY!,
	libraries: ['places'],
	LoadingContainer: InputLoader,
})(GooglePlacesAutocompleteComponent as unknown as IAutocomplete);
