import { LatLng } from 'apiFolder/directus-main';
import { ClinicCard } from 'templates/booking';

const R = 6371e3; // metres

const getItemsWithinRadius = (latLng1: LatLng, list: ClinicCard[], radius: number) => {
	if (latLng1 === null || !latLng1.lat || !latLng1.lng) return;

	const lat1 = latLng1.lat;
	const lon1 = latLng1.lng;

	return list.filter((latLng2: ClinicCard) => {
		if (!latLng2.location || !latLng2.location.lat || !latLng2.location.lng) {
			return;
		}
		const { lat, lng } = latLng2.location;

		const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
		const φ2 = (lat * Math.PI) / 180;
		const Δφ = ((lat - lat1) * Math.PI) / 180;
		const Δλ = ((lng - lon1) * Math.PI) / 180;

		const a =
			Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		const d = R * c; // in metres

		if (d > radius * 1000) {
			return null;
		}
		latLng2.distance = d;

		return latLng2;
	});
};

export default getItemsWithinRadius;
